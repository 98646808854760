var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{attrs:{"gutter":0}},[_c('div',{staticClass:"top"},[_c('el-col',{attrs:{"span":24}},[_c('el-form',{attrs:{"inline":"","size":"small"}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"输入顾客姓名","clearable":true},model:{value:(_vm.page.customerName),callback:function ($$v) {_vm.$set(_vm.page, "customerName", $$v)},expression:"page.customerName"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"icon":"el-icon-search","size":"small","type":"primary"},on:{"click":function($event){_vm.page.currentPage=1;_vm.getRecordsList()}}},[_vm._v("查询")])],1)],1)],1)],1),_c('RzTableVue',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"columns":_vm.columns,"data":_vm.tableData,"page":_vm.page,"stripe":"","border":true},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getStatus(row.status))+" ")]}},{key:"serviceDuration",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.serviceDuration)+"分钟 ")]}},{key:"consultTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatTime(row.consultTime))+" ")]}},{key:"consultEndTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatTime(row.consultEndTime))+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"action_detail",attrs:{"type":"text"},on:{"click":function($event){return _vm.detail(row)}}},[_vm._v("详情")])]}}])}),_c('record',{ref:"detail"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }