import axios from "axios";
import {Message} from "element-ui";
// import {showLoading, hideLoading} from "./Loading";
import store from '@/store';
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'


const baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API : '';
const request = axios.create({
    baseURL,
    timeout: 10000
});
// 请求拦截
request.interceptors.request.use((request) => {
    Nprogress.start()
    const {token} = store.state
    // 是否存在token
    if (token) {
        request.headers['Authorization'] = 'Bearer ' + token
    }
    // 开启Loading
    // showLoading()
    return request;
}, err => {
    return Promise.reject(err)
});
// 响应拦截
request.interceptors.response.use((response) => {
    Nprogress.done()
    const {data: {code, msg, error}} = response
    if (code == 500) {
        Message({
            showClose: true,
            message: msg || error.message,
            type: 'error'
        })
        return Promise.reject()
    }
    // else {
    //     Message({
    //         showClose: true,
    //         message: response.data.error.message,
    //         type: 'error'
    //     })
    // }
    // token过期
    if (response.data.error && response.data.error.code === 100010) {
        store.commit('removeToken');
        Message({
            showClose: true,
            message: response.data.error.message,
            type: 'error'
        });
    }
    // 关闭Loading
    // hideLoading();
    return response.data
}, error => {
    const {response: {data: {code, msg}}} = error
    if (code === 500) {
        Message({
            showClose: true,
            message: msg,
            type: 'error'
        });
    }
    return Promise.reject(error)
    // if (response.data.code != 200 || response.status !=200) {
    //   Message.error(response.data.message);
    //   return Promise.reject();
    // } else {
    //   return response.data;
    // }
});

export default request
