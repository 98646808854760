import axios from '../utils/request';
import qs from 'qs'
const UAA = process.env.VUE_APP_UAA;
const CONSULT = process.env.VUE_APP_CONSULT;

// 图片上传
export  function upLoad(data) {
    return axios({
        url: CONSULT + '/file/upload',
        method: 'post',
        data
    })
}
// 视频下载
export  function download(params) {
    return axios({
        url: CONSULT + '/file/fileDownload' + '?' + qs.stringify(params, {indices: false}),
        method: 'post',
        responseType: 'blob'
    })
}

//    获取视频
export  function getConsultVideo(data){
    return axios({
        url: CONSULT + '/file/fileDownload?videoUrl=' + data,
        method:"post",
    })
}

// 登录
export  function login(data) {
    return axios({
        url: UAA + '/oauth/token',
        method: 'POST',
        params: data
    })
}

// 根据登录用户，获取用户信息
export function userInfo() {
    return axios({
        url: CONSULT + '/admin/get-admin',
        method: 'get'
    })
}

/**
 *  咨询服务管理
 */

// 获取咨询服务列表
export function getList(data) {
    return axios({
        url: CONSULT + '/cs/content/page',
        method: 'post',
        data
    })
}
// 新增
export function addList(data) {
    return axios({
        url: CONSULT + '/cs/content/add-content',
        method: 'post',
        data
    })
}
// 获取咨询师下拉列表
export function getCounselorList() {
    return axios({
        url: CONSULT + '/cs/teacher/list',
        method: 'get',
    })
}

// 删除咨询服务信息
export function del(data) {
    return axios({
        url: CONSULT + '/cs/content/delete-content',
        method: 'post',
        data
    })
}
// 修改服务信息
export function upData(data) {
    return axios({
        url: CONSULT + '/cs/content/update-content',
        method: 'post',
        data
    })
}

// 修改服务状态信息
export function changeContentStatus(data) {
    return axios({
        url: CONSULT + '/cs/content/change-state',
        method: 'post',
        params: data
    })
}

/**
 *  咨询师管理
 */
// 获取咨询服务列表
export function getConsultantList(data) {
    return axios({
        url: CONSULT + '/cs/teacher/page',
        method: 'post',
        data
    })
}
// 获取咨询师账户列表
export function getConsultantAccount(data) {
    return axios({
        url: CONSULT + '/cs/teacher/getByTeacherId',
        method: 'get',
        params: data
    })
}
// 咨询师 账户重置密码
export function resetPwd(data) {
    return axios({
        url: CONSULT + '/admin/defult-pwd',
        method: 'get',
        params: data
    })
}
// 修改咨询师账户信息
export function changePwd(data) {
    return axios({
        url: CONSULT + '/admin/change-pwd',
        method: 'post',
        data
    })
}

// 删除咨询师信息
export function delConsultant(data) {
    return axios({
        url: CONSULT + '/cs/teacher/delete-teacher',
        method: 'post',
        data
    })
}

// 新增
export function addConsultant(data) {
    return axios({
        url: CONSULT + '/cs/teacher/add-teacher',
        method: 'post',
        data
    })
}

// 修改咨询师信息
export function updataConsultant(data) {
    return axios({
        url: CONSULT + '/cs/teacher/update-teacher',
        method: 'post',
        data
    })
}

/**
 *  顾客管理
 */

// 获取顾客列表
export function getClientList(data) {
    return axios({
        url: CONSULT + '/cs/customer/page',
        method: 'post',
        data
    })
}

// 添加顾客信息
export function addClient(data) {
    return axios({
        url: CONSULT + '/cs/customer/add-customer',
        method: 'post',
        data
    })
}

// 删除顾客信息
export function delClient(data) {
    return axios({
        url: CONSULT + '/cs/customer/delete-customer',
        method: 'post',
        data
    })
}

// 修改客户信息
export function updataClient(data) {
    return axios({
        url: CONSULT + '/cs/customer/update-customer',
        method: 'post',
        data
    })
}


/**
 *  顾客管理
 */

// 获取顾客列表
export function getRecordList(data) {
    return axios({
        url: CONSULT + '/cs/record/page',
        method: 'post',
        data
    })
}
