<script>
const scopedSlots = (h, props, conf, context) => {
  const nodeName = conf.nodeName || "div";
  const nodeObj = {
    attrs: {
      type: conf.type
    }
  };
  let childNode = [];
  if (conf.scopedSlots && context.$scopedSlots[conf.scopedSlots]) {
    childNode = h(nodeName, nodeObj, context.$scopedSlots[conf.scopedSlots](props));
  } else {
    childNode = h(nodeName, nodeObj, props.row[conf.key]);
  }
  return childNode;
};
const tableColumn = (h, list, context) => {
  if (!list) {
    list = [];
  }
  return list.map(item => {
    const nodeObj = {
      props: {},
      style: {}
    };
    const childNode = [];

    Object.keys(item).map(key => {
      if (key != "slots" && key != "scopedSlots") {
        nodeObj.props[key] = item[key];
        // if (key !== 'align') {
        //     nodeObj.props['align'] = 'center'
        // }
      }
    });

    if (item.key !== "index") {
      nodeObj.scopedSlots = {
        default: props => scopedSlots(h, props, item, context)
      };
    }

    // 自定义表头
    if (item.slots && context.$scopedSlots[item.slots.header]) {
      const slots = h("template", { slot: "header" }, context.$scopedSlots[item.slots.header](item));
      childNode.push(slots);
    }
    return h("el-table-column", nodeObj, childNode);
  });
};
export default {
  name: "CustomTable",
  props: {
    // 渲染数据
    data: {
      type: Array,
      required: true
    },
    border: {
      type: Boolean
    },
    treeProps: {
      type: Object
    },
    rowKey: {
      type: String
    },
    stripe: {
      type: Boolean
    },
    columns: {
      type: Array,
      required: true
    },
    spanMethod: {
      type: Function
    },
    rowClassName: {
      // 行类名的回调
      type: String
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    headerCellStyle: {
      type: Object,
      default: () => {
        return {
          // padding: '4px 0'
          // backgroundColor: '#f1f1f1',
          // borderBottom: 0
        };
      }
    },
    cellStyle: {
      type: Object,
      default: () => {
        return {
          // padding: '2px 0',
          borderBottom: 1
        };
      }
    },
    height: {
      type: String,
      default: "700px"
    },
    page: {
      type: Object
    }
  },
  render(h) {
    let $props = { ...this.$props };
    $props["header-cell-style"] = { "background-color": "#DCDCDC" };
    $props["header-row-style"] = { "background-color": "#F9F9F9" };
    const tableObj = {
      class: "custom-table",
      props: {
        ...$props,
        height: $props.height
      },
      style: {},
      on: {
        select: (selection, row) => {
          this.$emit("select", selection, row);
        },
        "select-all": selection => {
          this.$emit("select-all", selection);
        },
        "selection-change": selection => {
          this.$emit("selection-change", selection);
        },
        "row-click": (row, column, event) => {
          this.$emit("row-click", row, column, event);
        },
        "row-contextmenu": (row, column, event) => {
          this.$emit("row-contextmenu", row, column, event);
        },
        "row-dblclick": (row, column, event) => {
          this.$emit("row-dblclick", row, column, event);
        },
        /**
         * (expandedRows | expanded) （展开行时，回调的第二个参数为 expandedRows；树形表格时第二参数为 expanded）
         */
        "expand-change": (row, expandedRows) => {
          this.$emit("expand-change", row, expandedRows);
        }
      },
      ref: "table"
    };
    // 是否渲染分页组件
    const showPage = Object.keys({ ...this.page }).map(key => {
      key;
    });
    const tableNode = tableColumn(h, this.columns, this) || [];
    const elTable = h("el-table", tableObj, tableNode);
    const pageNode = [elTable];
    if (showPage.length) {
      const paginationObj = {
        props: {
          "hide-on-single-page": false,
          currentPage: this.page.currPage || 1,
          pageSizes: this.page.pageSizes || [10, 20, 50, 100],
          pageSize: this.page.pageSize || 10,
          layout: this.page.layout || "total, sizes, prev, pager, next, jumper",
          total: this.page.totalCount || 0
        },
        on: {
          "size-change": val => {
            this.$emit("size-change", val);
          },
          "current-change": val => {
            this.$emit("current-change", val);
          }
        }
      };
      const pagination = h("el-pagination", paginationObj);
      pageNode.push(pagination);
    }
    return h("div", { class: "table-page" }, pageNode);
  },
  methods: {
    clearSelection() {
      this.$refs.table.clearSelection();
    },
    toggleRowSelection(row, selected) {
      this.$refs.table.toggleRowSelection(row, selected);
    },
    toggleAllSelection() {
      this.$refs.table.toggleAllSelection();
    }
  }
};
</script>

<style lang="scss" scoped>
.table-page {
  .el-pagination {
    margin-top: 10px;
    text-align: right;
  }
}

::v-deep .el-table__header th {
  background-color: transparent;
  color: #201F1F;
}

::v-deep .el-table__body-wrapper {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 5px;
  }
}
</style>
