<template>
  <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="show"
      center
      title="重置密码"
      width="500px">
    <el-form ref="form" :model="form" label-position="left"
             label-width="80px" status-icon>
      <el-form-item label="账号">
        <span>{{ form.loginName }}</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="resetPwd()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import  { resetPwd } from '@/api'

export default {
  name: "ResetPwd",
  data() {
    return {
      form: {},
      show: false,
      id: ''
    }
  },
  methods: {
    // 重置密码
    resetPwd() {
      this.$confirm('此操作将重置该账户密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const id = {
          id: this.form.id
        }
        let r = await resetPwd(id)
        if (r.code == 200) {
          this.$message.success('重置密码成功')
          this.show = false
        }
      }).catch(err => err)
    },
    open(e) {
      this.show = true
      this.form = e
    }
  }
}
</script>

<style lang="scss" scoped>

</style>