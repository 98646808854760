<template>
  <el-row>
    <div class="top">
      <el-col :span="24">
        <el-form inline size='small'>
          <el-form-item>
            <el-input placeholder="输入服务名称" v-model="page.serviceName" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="输入咨询师名字" v-model="page.consultantName" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-search'
                       @click="page.currentPage=1;getList()"
                       size="small" type="primary">查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-form inline size='small' style="text-align: right">
        <el-form-item class="top_right">
          <el-button icon='el-icon-circle-plus-outline' size="small" type="primary" @click='addData'>新增</el-button>
          <!--          批量删除-->
          <el-button icon='el-icon-delete' size="small" class="del" @click="deleteService()">删除</el-button>
        </el-form-item>
      </el-form>
    </div>
    <RzTableVue v-loading='loading'
                :columns='columns'
                :data='tableData'
                :page='page'
                stripe
                show-overflow-tooltip
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @selection-change='selection'
                :border="true">
      <template #serviceImage="{row}">
        <el-image :src="row.serviceImage" style="width: 50px; height: 50px" :preview-src-list="[row.serviceImage]">
          <div slot="error" class="image-slot">
            <img src="../../assets/img/failtoload50x50.png" />
          </div>
        </el-image>
      </template>
      <template #serviceDuration="{row}">
        <span>{{ row.serviceDuration }}分钟</span>
      </template>
      <template #price="{row}">
        <span>¥{{ row.price }}</span>
      </template>
      <template #state="{row}">
        <Status :status="row.state==1"></Status>
      </template>
      <template #action="{row}">
        <el-button class="action_status" type="text" @click="handleStatus(row)">
          {{ row.state == 1 ? '禁用' : '启用' }}
        </el-button>
        <el-button type='text' @click='update(row)' class="action_update">修改</el-button>
        <el-button type='text' @click='deleteService(row.id)' class="action_del">删除</el-button>
        <el-button type='text' @click='detail(row)' class="action_detail">详情</el-button>
      </template>
      <template #serviceOutline="{row}">
        <span class="text" v-html="row.serviceOutline"></span>
<!--        <el-tooltip :content="row.serviceOutline" class="item" effect="light" placement="top">-->
<!--          <span class="text" v-html="row.serviceOutline"></span>-->
<!--        </el-tooltip>-->
      </template>
      <template #serviceContent="{row}">
        <span class="text" v-html="row.serviceContent"></span>
<!--        <el-tooltip :content="row.serviceContent" class="item" effect="light" placement="top">-->
<!--          <span class="text" v-html="row.serviceContent"></span>-->
<!--        </el-tooltip>-->
      </template>
    </RzTableVue>
    <user ref="user" @success="getList()"/>
    <detail ref="detail"></detail>
  </el-row>
</template>

<script>
import RzTableVue from '../../components/table/RzTable.vue'
import user from './ServeAddDialog'
import detail from './ServeDetailDialog'
import { getList, del, changeContentStatus } from '@/api'
import Status from '@/components/RzStatus'
import appDialog from "@/views/information/application/ApplicationDialog";

export default {
  name: "Serve",
  components: {RzTableVue, user, detail, Status},
  data() {
    return {
      tableData: [],
      columns: [
        {
          type: 'selection',
          key: 'index',
          align: 'center'
        },
        {
          label: '服务名称',
          key: 'serviceName',
          prop: 'serviceName',
          align: 'center',
          // sortable: true,
        },
        {
          label: '服务概要',
          key: 'serviceOutline',
          prop: 'serviceOutline',
          align: 'center',
          scopedSlots: 'serviceOutline',
          // sortable: true,
        },
        {
          label: '服务头图',
          key: 'serviceImage',
          prop: 'serviceImage',
          align: 'center',
          scopedSlots: 'serviceImage',
          // sortable: true,
          width: '150px'
        },
        {
          label: '服务内容说明',
          key: 'serviceContent',
          prop: 'serviceContent',
          align: 'center',
          scopedSlots: 'serviceContent',
        },
        {
          label: '服务状态',
          key: 'state',
          prop: 'state',
          align: 'center',
          scopedSlots: 'state'
        },
        {
          label: '咨询师',
          key: 'consultantName',
          prop: 'consultantName',
          align: 'center',
          // sortable: true,
          width: '160px'
        },
        {
          label: '服务价格',
          key: 'price',
          prop: 'price',
          align: 'center',
          scopedSlots: 'price',
          // sortable: true,
        },
        {
          label: '服务时长',
          key: 'serviceDuration',
          prop: 'serviceDuration',
          align: 'center',
          scopedSlots: 'serviceDuration',
          // sortable: true,
        },
        {
          label: '操作',
          key: 'action',
          prop: 'action',
          align: 'center',
          // sortable: true,
          width: 190,
          scopedSlots: 'action',
        }
      ],
      loading: false,
      page: {
        serviceName: '',
        consultantName: '',
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
      },
      // 选择的多个id
      ids: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 监听 pageSize 改变的事件
    handleSizeChange(newSize) {
      this.page.pageSize = newSize
      this.getList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page.currentPage = newPage
      this.getList()
    },
    // 修改三方系统应用状态
    handleStatus(e) {
      changeContentStatus({
        id: e.id,
        state: e.state == 2 ? 1 : 2
      })
          .then(({code, data}) => {
            if (code == 200) {
              this.$message.success('操作成功')
              this.getList()
            }
          })
    },
    // 修改
    update(row) {
      this.$refs.user.title = '修改'
      this.$refs.user.form = {...row}
      this.$refs.user.dialogVisible = true
    },
    // 新增
    addData() {
      this.$refs.user.title = '新增'
      this.$refs.user.dialogVisible = true
    },
    // 详情
    detail(row) {
      this.$refs.detail.title = '详情'
      this.$refs.detail.form = {...row}
      this.$refs.detail.dialogVisible = true
    },
    // detail(row) {
    //   this.$refs.user.title = '详情'
    //   this.$refs.user.disable = true
    //   this.$refs.user.form = {...row}
    //   this.$refs.user.dialogVisible = true
    // },
    // 多项选择
    selection(e) {
      this.ids = e
    },
    // 删除咨询服务信息
    deleteService(id) {
      let ids = this.ids.map(val => {
        return val.id
      })
      if (!id && this.ids.length == 0) {
        this.$message.error('请选择数据')
        return false
      }
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let r = await del(id ? [id] : ids)
        if (r.code == 200) {
          this.$message.success('删除成功')
          this.getList()
        }
      }).catch(err => err)
    },
    // 获取咨询服务管理列表
    getList() {
      this.loading = true
      const query = {
        pageSize: this.page.pageSize,
        currPage: this.page.currentPage,
        serviceName: this.page.serviceName,
        consultantName: this.page.consultantName
      }
      getList(query).then(({code, data}) => {
        this.loading = false
        if (code == 200) {
          this.tableData = data.list
          this.page.totalCount = data.totalCount
        }
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .top {
  display: flex;
  justify-content: space-between;

  form.el-form.el-form--inline {
    width: 600px;
  }

  .el-col {
    padding-left: 0 !important;
  }

  .top_right {
    margin-right: 0 !important;
  }

  .add .search {
    background: #4E6FDA;
    color: #FFF8F8;
  }

  .del {
    background: #EC2B2B;
    color: #FFF8F8;
  }
}

::v-deep .text {
  display: inline-block;
  width: 10em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 35px;
}
</style>
