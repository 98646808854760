import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Layout from "@/layout/index.vue";

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//     if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//     return originalPush.call(this, location).catch(err => err)
// }
console.log(Layout);
const layout = Layout || (() => import(/* webpackChunkName: "about" */ '@/layout/index'))
const routes = [
    // { path: '/', redirect: '/login' ,props: { hide:true }},
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
        props: {
            hide: true
        }
    },
    {
        path: "/",
        redirect: '/serve',
        name: "home",
        component: layout,
        children: [
            {
                path: '/serve',
                name: '咨询服务管理',
                component: () => import(/* webpackChunkName: "about" */ '../views/serve/Serve.vue'),
                props: {
                    index: 0,
                    img: require('@/assets/serve.png'),
                    activeImg: require('@/assets/serve-s.png')
                }
            },
            {
                path: '/consultant',
                name: '咨询师管理',
                component: () => import(/* webpackChunkName: "about" */ '../views/consultant/Consultant.vue'),
                props: {
                    index: 1,
                    img: require('@/assets/consultant.png'),
                    activeImg: require('@/assets/consultant-s.png')
                }
            },
            {
                path: '/client',
                name: '顾客管理',
                component: () => import(/* webpackChunkName: "about" */ '../views/client/Client.vue'),
                props: {
                    index: 2,
                    img: require('@/assets/client.png'),
                    activeImg: require('@/assets/client-s.png')
                }
            },
            {
                path: '/consultingfiles',
                name: '咨询档案',
                component: () => import(/* webpackChunkName: "about" */ '../views/consultingFiles/ConsultingFiles.vue'),
                props: {
                    index: 3,
                    img: require('@/assets/record.png'),
                    activeImg: require('@/assets/record-s.png')
                }
            },
            {
                path: '/information',
                name: '三方信息管理',
                component: () => import(/* webpackChunkName: "about" */ '../views/information/Information'),
                props: {
                    index: 5,
                    // img:require('@/assets/record.png'),
                    // activeImg:require('@/assets/record-s.png')
                }
            },
            {
                path: '/system',
                name: '系统管理',
                component: () => import(/* webpackChunkName: "about" */ '../views/system/System'),
                props: {
                    index: 4,
                    // img:require('@/assets/record.png'),
                    // activeImg:require('@/assets/record-s.png')
                }
            }
        ]
    }

    // {
    //     path: '/system',
    //     name: '系统管理',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/system/System.vue'),
    //     props: {
    //         index: 4,
    //         // img:require('@/assets/record.png'),
    //         // activeImg:require('@/assets/record-s.png')
    //     },
    //     children: [
    //         {   path: '/system/users',
    //             name: '用户管理',
    //             component: () => import(/* webpackChunkName: "about" */ '../views/system/users/Users.vue') }
    //     ]
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 路由导航守卫 判断用户是否登录
router.beforeEach(((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        if (store.state.token) {
            next()
        } else {
            next({path: '/login'})
        }
    }
    // if (to.path === '/login') return next()
    // // 获取 token
    // if (!store.state.token) return next('/login')
    // next()
}))

export default router
