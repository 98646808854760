import axios from '../utils/request';
const UAA = process.env.VUE_APP_UAA;
const CONSULT = process.env.VUE_APP_CONSULT;

/**
 *  三方信息管理
 */
/**
 *  三方系统应用信息管理
 */

// 获取咨询应用列表
export function getAppList(data) {
    return axios({
        url: CONSULT + '/three/apply/page',
        method: 'get',
        params: data
    })
}

// 删除咨询应用信息
export function delApp(data) {
    return axios({
        url: CONSULT + '/three/apply/delete',
        method: 'post',
        data
    })
}

// 修改咨询应用信息
export function updateApp(data) {
    return axios({
        url: CONSULT + '/three/apply/update',
        method: 'post',
        data
    })
}

// 新增咨询应用信息
export function addApp(data) {
    return axios({
        url: CONSULT + '/three/apply/add',
        method: 'post',
        data
    })
}

// 修改应用状态信息
export function changeStatus(data) {
    return axios({
        url: CONSULT + '/three/apply/change-status',
        method: 'post',
        params: data
    })
}

/**
 *  客户单位管理
 */

// 获取客户单位列表
export function getOrgList(data) {
    return axios({
        url: CONSULT + '/three/org/page',
        method: 'get',
        params: data
    })
}

// 删除客户单位信息
export function delOrg(data) {
    return axios({
        url: CONSULT + '/three/org/delete',
        method: 'post',
        data
    })
}

// 修改客户单位信息
export function updateOrg(data) {
    return axios({
        url: CONSULT + '/three/org/update',
        method: 'post',
        data
    })
}

// 新增客户单位信息
export function addOrg(data) {
    return axios({
        url: CONSULT + '/three/org/add',
        method: 'post',
        data
    })
}

// 修改客户单位状态信息
export function changeOrgStatus(data) {
    return axios({
        url: CONSULT + '/three/org/change-status',
        method: 'post',
        params: data
    })
}

// 获取应用下拉列表
export function getAppIdList() {
    return axios({
        url: CONSULT + '/three/apply/list',
        method: 'get'
    })
}

/**
 *  服务套餐管理
 */

// 获取服务套餐列表
export function getMealList(data) {
    return axios({
        url: CONSULT + '/three/meal/page',
        method: 'get',
        params: data
    })
}

// 删除服务套餐信息
export function delMeal(data) {
    return axios({
        url: CONSULT + '/three/meal/delete',
        method: 'post',
        data
    })
}

// 修改服务套餐信息
export function updateMeal(data) {
    return axios({
        url: CONSULT + '/three/meal/update',
        method: 'post',
        data
    })
}

// 新增服务套餐信息
export function addMeal(data) {
    return axios({
        url: CONSULT + '/three/meal/add',
        method: 'post',
        data
    })
}


// 获取服务下拉列表
export function getContentList() {
    return axios({
        url: CONSULT + '/cs/content/list',
        method: 'get'
    })
}
// 获取机构下拉列表
export function getInsList() {
    return axios({
        url: CONSULT + '/three/org/list',
        method: 'get'
    })
}

/**
 *  咨询记录管理
 */

// 获取咨询记录列表
export function getRecordsList(data) {
    return axios({
        url: CONSULT + '/three/record/page',
        method: 'get',
        params: data
    })
}
