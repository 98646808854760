<template lang="html">
    <div class="editor">
        <div ref="toolbar" class="toolbar"></div>
        <div ref="editor" class="text"></div>
    </div>
</template>

<script>
import { getValue } from '@/utils'
import { upLoad } from '@/api'
import E from 'wangeditor'
export default {
    name: 'editoritem',
    data() {
        return {
            token: getValue(),
            // menus: [
            //     'head',
            //     'bold',
            //     'fontSize',
            //     'fontName',
            //     'italic',
            //     'underline',
            //     'strikeThrough',
            //     'indent',
            //     'lineHeight',
            //     'foreColor',
            //     'backColor',
            //     'link',
            //     'list',
            //     'todo',
            //     'justify',
            //     'quote',
            //     'emoticon',
            //     'image',
            //     'video',
            //     'table',
            //     'code',
            //     'undo',
            //     'redo',
            // ],
            editor: null,
            info_: null,
            fontNames: ['"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB"', 'Microsoft YaHei', '微软雅黑', 'Arial', 'sans-serif', 'pop', 'xingcao', 'wenhan']
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        isClear: {
            type: Boolean,
            default: false
        },
        // menus: {
        //     type: Array,
        //     default: () => {
        //         return [
        //             'head', // 标题
        //             'bold', // 粗体
        //             'fontSize', // 字号
        //             'fontName', // 字体
        //             'italic', // 斜体
        //             'underline', // 下划线
        //             'strikeThrough', // 删除线
        //             'foreColor', // 文字颜色
        //             'backColor', // 背景颜色
        //             'link', // 插入链接
        //             'indent', // 缩进
        //             'list', // 列表
        //             'justify', // 对齐方式
        //             'quote', // 引用
        //             'emoticon', // 表情
        //             'image', // 插入图片
        //             'table', // 表格
        //             'video', // 插入视频
        //             'code', // 插入代码
        //             'undo', // 撤销
        //             'redo', // 重复
        //             'fullscreen' // 全屏
        //         ]
        //     }
        // },
        fullScreen: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 300
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        isClear(val) {
            // 触发清除文本域内容
            if (val) {
                this.editor.txt.clear()
                this.info_ = null
            }
        },
        value: function(value) {
            if (value !== this.editor.txt.html()) {
                this.editor.txt.html(this.value)
            }
        }
        //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
    },
    mounted() {
        this.seteditor()
        if (this.disabled) {
            this.editor.disable()
        }
    },
    methods: {
        seteditor() {
            // http://192.168.2.125:8080/admin/storage/create
            this.editor = new E(this.$refs.toolbar)
            this.editor.config.uploadImgShowBase64 = false // base 64 存储图片
            this.editor.config.uploadImgServer = process.env.VUE_APP_API + '/xm/file/upload' // 配置服务器端地址
            this.editor.config.uploadImgHeaders = {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + this.token
            } // 自定义 header
            this.editor.config.uploadFileName = 'file' // 后端接受上传文件的参数名
            this.editor.config.uploadImgMaxSize = 5 * 1024 * 1024 // 将图片大小限制为 2M
            this.editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
            this.editor.config.uploadImgMaxLength = 1 // 限制一次最多上传 3 张图片
            this.editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
            this.editor.config.focus = false
            this.editor.config.showLinkImg = false // 隐藏插入网络图片的功能
            this.editor.config.withCredentials = true // 跨域上传中如果需要传递 cookie 需设置 withCredentials

            // 配置菜单
            // this.editor.config.menus = this.menus
            // 配置字体
            this.editor.config.fontNames = this.fontNames
            // 设置编辑区域的高度
            this.editor.config.height = this.height - 24
            // 设置显示全屏按钮
            this.editor.config.showFullScreen =  this.fullScreen
            // 自定义上传图片
            this.editor.config.customUploadImg = async function(resultFiles, insertImgFn) {
                // resultFiles 是 input 中选中的文件列表
                // insertImgFn 是获取图片 url 后，插入到编辑器的方法
                const file = resultFiles[0]
                const formData = new FormData()
                formData.append('file', file)
                let res = await upLoad.uploadFile(formData)
                const { status, data } = res
                if (status == 200) {
                    // 上传图片，返回结果，将图片插入到编辑器中
                    //    insertImgFn(data.url)
                    if (typeof FileReader === 'function') {
                        const reader = new FileReader()
                        reader.readAsDataURL(file)
                        reader.onload = event => {
                            var Base64Val = event.target.result
                            // success(Base64Val)
                            insertImgFn(Base64Val)
                        }
                    }
                }
            }
            this.editor.config.onchange = html => {
                this.info_ = html // 绑定当前逐渐地值
                this.$emit('change', this.info_) // 将内容同步到父组件中
            }
            this.editor.config.onblur = html => {
                this.info_ = html //获取最新的 html 内容
                this.$emit('blur', this.info_) // 将内容同步到父组件中
            }
            this.editor.config.onfocus = html => {
                this.info_ = html // 获取最新的 html 内容
                this.$emit('focus', this.info_) // 将内容同步到父组件中
            }
            // 创建富文本编辑器
            this.editor.create()
            this.editor.txt.html(this.value)
        }
    }
}
</script>

<style lang="scss" scoped>
.editor {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 0;
}
.toolbar {
    border: 1px solid #ccc;
    border-bottom: 0;
}
::v-deep .w-e-toolbar .w-e-menu {
    width: 30px;
    height: 30px;
}
.text {
    border: 1px solid #ccc;
}
</style>
