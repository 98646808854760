<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="20%"
      @closed="resetFields"
      top="5vh"
      :close-on-click-modal="false"
      center>
    <el-form label-width="120px"
             label-position="left"
             :model="form"
             :rules="addRule"
             :disabled="disable"
             ref="addForm">
      <el-form-item label="管理员名字" prop="realName">
        <el-input v-model="form.realName" placeholder="输入管理员名字"></el-input>
      </el-form-item>
      <el-form-item label="登录名称" prop="loginName">
        <el-input v-model="form.loginName" placeholder="输入管理员名字"></el-input>
      </el-form-item>
      <el-form-item label="登录密码">
        <el-input v-model="form.loginPwd" disabled show-password></el-input>
      </el-form-item>
      <el-form-item label="身份证" prop="idcard">
        <el-input v-model="form.idcard" placeholder="输入身份证号"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="mobile">
        <el-input v-model="form.mobile" placeholder="输入电话号码"></el-input>
      </el-form-item>
      <el-form-item label="头像">
        <el-upload  :show-file-list="false"
                    action=""
                    :http-request="uploadAvatar"
                    accept=".jpg,.jpeg,.png"
                    :limit="1"
                    ref="uploadAvatar"
                    :on-success="handleUploadAvatarSuccess"
                    list-type="picture-card">
          <i class="el-icon-plus" v-show="!form.headerImg"></i>
          <img :src="form.headerImg" alt="" v-show="form.headerImg">
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowSave" v-show="!disable">确 定</el-button>
      <el-button @click="dialogVisible = false" v-show="!disable">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { phoneCheck,idCard } from "@/utils/formCheck"
import { upLoad } from '@/api'
import { addUsers,  updateUsers } from '@/api/sys'

export default {
  name: "UsersDialog",
  data() {
    return {
      title: '',
      form: {},
      dialogVisible: false,
      // 是否禁用所有项
      disable: false,
      // 新增的校验规则
      addRule: {
        realName: [{required: true, message: "管理员名字不能为空", trigger: "blur"}],
        loginName: [{required: true, message: "登录名称不能为空", trigger: "blur"}],
        level: [{required: true, message: "该项不能为空", trigger: "blur"}],
        idcard: [{required: true, message: "身份证不能为空", trigger: "blur"},
          { validator: idCard, message: "身份证格式不正确", trigger: "blur" }],
        mobile: [{required: true, message: "电话不能为空", trigger: "blur"},
          { validator: phoneCheck, message: "手机号码格式不正确", trigger: "blur" }],
      }
    }
  },
  methods: {
    handleShow() {
      this.dialogVisible = true
    },
    // 图片上传成功时
    handleUploadSuccess(response, file, fileList) {
      this.$refs.upload.clearFiles()
    },
    handleUploadAvatarSuccess(response, file, fileList) {
      this.$refs.uploadAvatar.clearFiles()
    },
    // 重置表单信息
    resetFields() {
      this.disable = false
      this.form={}
      this.$refs.addForm.resetFields()
    },
    // 保存
    rowSave() {
      this.$refs.addForm.validate(valid => {
        if(!valid) {
          return this.$message.error('请填写必要的表单项！')
        }
        const row = this.form
        this.loading = true
        // 如果存在该条信息id 则为修改信息
        if(row.id) {
          updateUsers(row).then( data => {
            if(data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
              .catch(err => {
                this.loading = false
              })
        } else {
          addUsers(row).then( data => {
            if(data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
              .catch(err => {
                this.loading = false
              })
        }
      })
    },
    // 头像 上传
    async uploadAvatar({data,file}) {
      const formData = new FormData
      formData.append('file',file)
      let response = await upLoad(formData)
      if(response.code == 200) {
        this.form.headerImg = response.data
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
img{
  width: 148px;
  height: 148px;
  object-fit: cover;
}
::v-deep .el-input__inner::-webkit-input-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-moz-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-ms-input-placeholder{
  color: #d9d9d9 !important;
}

</style>
