<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      @closed="resetFields"
      :close-on-click-modal="false"
      center>
    <el-form label-width="120px" :model="form" :rules="addRule" ref="addForm" :disabled="disable">
      <el-row :gutter="0">
        <el-col :span="9">
          <el-form-item label="服务名称" size="mini" prop="serviceName">
            <el-input v-model="form.serviceName" placeholder="输入服务名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="5" :span="9">
          <el-form-item label="服务时长" size="mini" prop="serviceDuration">
            <el-input v-model="form.serviceDuration" placeholder="设置时间（分钟）" suffix-icon="el-icon-date"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="0">
        <el-col :span="9">
          <el-form-item label="设置价格" size="mini" prop="price">
            <el-input v-model="form.price" placeholder="单位：元"></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="5" :span="9">
          <el-form-item label="咨询师" size="mini" prop="consultant">
            <el-select placeholder="选择咨询师" v-model="form.consultantId">
              <el-option v-for="(item,index) in counselorList"
                         :key="index"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="9">
          <el-form-item label="服务头图">
            <el-upload  action=""
                        :http-request="upload"
                        accept=".jpg,.jpeg,.png"
                        list-type="picture-card"
                        :show-file-list="false"
                        ref="upload"
                        :on-success="handleUploadSuccess"
                        :limit="1"
                        >
              <i class="el-icon-plus" v-show="!form.serviceImage"></i>
              <el-image :src="form.serviceImage" style="width: 150px; height: 150px" v-show="form.serviceImage">
                <div slot="error" class="image-slot">
                  <img src="../../assets/img/failtoload150x150.png" />
                </div>
              </el-image>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="24">
          <el-form-item label="服务概要" size="mini" prop="serviceOutline">
            <editor v-model="form.serviceOutline"></editor>
            <!--            <el-input v-model="form.serviceOutline" placeholder="输入服务概要" type="textarea" :autosize="{ minRows: 4 }"></el-input>-->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="24">
          <el-form-item label="服务内容说明" size="mini" prop="serviceContent">
            <editor v-model="form.serviceContent"></editor>
            <!--            <el-input v-model="form.serviceContent" placeholder="输入服务内容说明" type="textarea" :autosize="{ minRows: 4 }"></el-input>-->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowSave" v-show="!disable">确 定</el-button>
      <el-button @click="dialogVisible = false" v-show="!disable">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addList, getCounselorList, upLoad, upData } from '../../api'
import editor from '@/components/RzTextEditor'

export default {
  name: "ServeAddDialog",
  components: {
    editor
  },
  data() {
    return {
      title: '',
      form: {
        serviceName: '', // 服务名称
        serviceOutline: '', // 服务概要
        serviceImage: '', // 服务头图
        serviceContent: '', // 服务内容说明
        consultantName: '', // 咨询师
        consultantId: [], // 咨询师ID
        consultantProfile: '', // 咨询师简介
        serviceDuration: '', // 服务时长
        price: '' // 服务价格
      },
      value: '',
      // 获取咨询师列表
      counselorList: [],
      dialogVisible: false,
      // 是否禁用所有项
      disable: false,
      // 新增的校验规则
      addRule: {
        serviceName: [{required: true, message: "服务名称不能为空", trigger: "blur"}],
        serviceDuration: [{required: true, message: "服务时长不能为空", trigger: "blur"},
          {validator: this.validateNum, trigger: "blur"}],
        serviceOutline: [{required: true, message: "服务概要不能为空", trigger: "blur"}],
        serviceContent: [{required: true, message: "服务内容说明不能为空", trigger: "blur"}],
        // consultant: [{required: true, message: "必须选择咨询师", trigger: "blur"}],
        price: [{required: true, message: "价格不能为空", trigger: "blur"},
          {validator: this.validateNum, trigger: "blur"}],
      }
    }
  },
  created() {
    this.getCounselorList()
  },
  mounted() {
  },
  methods: {
    handleShow() {
      this.dialogVisible = true
    },
    // 验证表单-服务时长必须为整数数字
    validateNum(rule, value, callback) {
      if(!Number(value)) {
        return callback(new Error("请输入数字值"));
      } else {
        return callback()
      }
    },
    // 图片上传成功时
    handleUploadSuccess(response, file, fileList) {
      this.$refs.upload.clearFiles()
    },
    // 重置表单信息
    resetFields() {
      this.disable = false
      this.form ={}
      this.$refs.addForm.resetFields()
    },
    // 保存
    rowSave() {
      this.$refs.addForm.validate(valid => {
        if (!valid) {
          return this.$message.error('请填写必要的表单项！')
        }
        const row = this.form
        row.consultantName = this.counselorList.filter(v=> {
          return v.id == row.consultantId
        })[0].name
        row.consultantProfile = this.counselorList.filter(v=> {
          return v.id == row.consultantId
        })[0].profile
        // row.consultantName = []
        // row.consultantId.forEach(v=>{
        //   this.counselorList.forEach(j=>{
        //     if (v==j.id){
        //       row.consultantName.push(j.name)
        //     }
        //   })
        // })
        // row.consultantId =  row.consultantId.join()
        // row.consultantName = row.consultantName.join()
        this.loading = true
        if(row.id) {
          upData(row).then(data => {
            if (data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
          .catch(err => {
            this.loading = false
          })
        } else {
          addList(row).then( data => {
            if (data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
    // 获取咨询师列表
    getCounselorList() {
      getCounselorList().then(data => {
        if (data.code === 200) {
          this.counselorList = data.data
        } else {
          this.$message.error(error.message)
        }
      })
          .catch(error => {
          })
    },
    // 图片上传
    async upload({data,file}) {
      const formData = new FormData
      formData.append('file',file)
      let response = await upLoad(formData)
      if(response.code == 200) {
        this.form.serviceImage = response.data
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//img{
//  width: 200px;
//  height: 200px;
//  object-fit: cover;
//}
//::v-deep .text{
//  border: unset !important;
//}
</style>
