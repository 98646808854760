import md5 from 'md5'

// 加盐
export const formatPwd = function(pwd) {
    const salt = 'psy-api-2021';
    let str = salt.charAt(0) + salt.charAt(3) + pwd + salt.charAt(5) + salt;
    return md5(str).toUpperCase();
}

// 本地存
export const setValue = (key, value) => {
    localStorage.setItem(key, value)
}

// 本地取
export const getValue = (key) => {
    return localStorage.getItem(key)
}

// 清除本地key
export const removeKey = (key) => {
    localStorage.removeItem(key)
}

// 防抖
export function debounce(fn, delay = 500) {
    // timer 是闭包中的
    let timer = null
    return function () {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn.apply(this, arguments)
            timer = null
        }, delay)
    }
}

// 视频下载
export function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url) // 创建blob地址
    }
    var aLink = document.createElement('a')
    aLink.href = url
    aLink.download = saveName || '' // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event
    if (window.MouseEvent) event = new MouseEvent('click')
    else {
        event = document.createEvent('MouseEvents')
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    }
    aLink.dispatchEvent(event)
}
