<template>
 <div>
   <div class="login">
     <div class="login-box">
       <span class="login-box-title">登录</span>
       <span class="login-box-sub-title">欢迎您，来到咨询系统！</span>
       <el-form :model="form" :rules="rules" ref="form" status-icon>
         <el-form-item prop="username">
           <el-input v-model="form.username" prefix-icon="el-icon-user" placeholder="输入账号/电话" @keydown.enter.native="login()"></el-input>
         </el-form-item>
         <el-form-item prop="password">
           <el-input v-model="form.password" prefix-icon="el-icon-suitcase" type="password" placeholder="输入密码" @keydown.enter.native="login()"></el-input>
         </el-form-item>
       </el-form>
       <el-button type="primary" @click="login()" :loading="loading">登录{{ loading ? '中···' : '' }}</el-button>
     </div>
   </div>
   <el-footer style="height: 30px !important;">
     <el-link class='company_link'
              href='http://xinli121.com/'
              target='_blank' :underline="false">技术服务支持 ©{{ (new Date()).getFullYear() }} 辰微沐阳
     </el-link>
   </el-footer>
 </div>
</template>

<script>
import { login } from '../api'
import { mapMutations, mapActions } from 'vuex'
import { formatPwd } from '@/utils'

export default {
  name: "login",
  data() {
    var checkUser = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账号不能为空'))
      } else {
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      form: {
        username: 'admin',
        password: '123456',
        grant_type: 'password',
        client_id: 'admin-app',
        client_secret: 'B1538757C07E6A6AD2D4F71A4986E6B6',
        relogin: 1
      },
      rules: {
        username: [{ validator: checkUser, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
      }
    }
  },
  methods:{
    ...mapMutations(['setToken']),
    ...mapActions(['handleUserInfo']),
    // 登录
    login(){
      this.$refs.form.validate(valid => {
        if (!valid) {
          return this.$message.error('请填写必要的登录信息！')
        }
        this.loading = true

        const params = { ...this.form }
        // 加盐
        params.password = formatPwd(params.password)

        login(params).then(res => {
          // 存token
          this.setToken(res.data.accessToken);
          this.handleUserInfo()
          this.$router.push({path:'/serve'})
        }).catch( err => err).finally(result => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: calc(100vh - 30px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  background: url("../assets/login-bg.png") no-repeat 100%;

  &-box {
    width: 576px;
    height: 410px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    opacity: 1;
    border-radius: 10px;
    padding: 30px 42px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin:0 auto;

    &-title {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #4E6FDA;
      margin-bottom: 14px;
      letter-spacing: 5px;
    }

    &-sub-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #808080;
      margin-bottom: 48px;
    }

    .el-form {
    }

    .el-button {
      width: 240px;
      margin: 20px auto 0;
    }
  }
}
</style>
