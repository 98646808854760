<template>
  <el-tabs v-model="activeName"  @tab-click="handleClick">
    <el-tab-pane label="用户管理" name="first"><users v-if="isFirst"></users></el-tab-pane>
    <el-tab-pane label="字典管理" name="second"><dict v-if="isSecond"></dict></el-tab-pane>
  </el-tabs>
</template>

<script>
import users from './users/Users'
import dict from './dict/Dict'

export default {
  name: "Information",
  components: { users, dict },
  data() {
    return {
      activeName: 'first',
      isFirst: true,
      isSecond: false,
      isThird: false,
      isFourth: false
    }
  },
  methods: {
    handleClick(tab, event) {
      // localStorage.setItem('task', tab.name)
      // 根据切换的tab栏来请求后端数据
      if (tab.name === 'first') {
        this.isFirst= true
        this.isSecond= false
      } else  if (tab.name === 'second') {
        this.isFirst= false
        this.isSecond= true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
