// 手机验证
export const phoneCheck = function(rule, value, callback) {
    if (!/0?(13|14|15|17|18|19)[0-9]{9}/.test(value)) {
        callback(new Error('电话号码格式不正确'))
    } else {
        callback()
    }
}
// 身份证验证
export const idCard = (rule, value, callback) => {
    if (!/\d{17}[\d|x]|\d{18}/.test(value)) {
        return callback(new Error('身份证号格式不正确'))
    } else {
        callback()
    }
}
// 生日时间区间
export const birthday = (rule, value, callback) => {
    let dt = new Date()
    let v_dt = new Date(value)
    if (Date.parse(dt) <= Date.parse(v_dt)) {
        return callback(new Error('生日必须小于当前日期'))
    } else {
        return callback()
    }
}
