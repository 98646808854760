<template>
  <el-dialog :title="title"
             :visible.sync="dialogVisible"
             width="35%"
             :close-on-click-modal="false"
             @closed="resetFields"
             top="5vh"
             center>
    <el-form :model="form">
      <el-row>
        <span class="title">咨询过程内容:</span>&nbsp&nbsp
        <span class="content" v-html="form.content"></span>
      </el-row>
      <el-row>
        <span class="title">咨询视频:</span>&nbsp&nbsp
        <span class="content">
          <el-button type="success"
                     round
                     size="mini"
                     v-if="form.videoUrl"
                     :disabled="btnDisabled"
                     @click="download()">查看视频</el-button>
          <el-button type="warning"
                     round
                     size="mini"
                     v-else
                     :disabled="true">暂无视频</el-button>
        </span>
      </el-row>
      <el-row>
        <span class="title">备注:</span>&nbsp&nbsp
        <span class="content">{{form.remarks}}</span>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import videoDownload from '@/mixins/videoDownload'

export default {
  name: "RecordDialog",
  mixins: [videoDownload]
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 5px 0;
  .el-dialog__title {
    font-weight: 400;
    line-height: 28px;
  }
  .el-dialog__headerbtn {
    top: 8px;
  }
}
::v-deep .el-dialog__body {
  padding: 0;
  .el-form {
    padding: 30px 15px 5px;
    //border-bottom: 9px solid rgba(241, 241, 241, 1);
    .el-row {
      margin-bottom: 20px;
      .price {
        font-size: 20px;
        font-weight: bolder;
        color: rgba(78, 111, 218, 1);
      }
      .title {
        font-weight: bold;
        color: rgba(16, 16, 16, 1);
      }
      .content {
        color: rgba(133, 133, 133, 1);
      }
      .img {
        .el-image {
          border-radius: 10px;
          width: 100%;
          height: 100%;
        }
      }
      .name {
        color: rgba(0, 0, 0, 1);
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        padding-right: 10px;
      }
    }
    .el-row:last-child {
      margin-bottom: 0;
    }
  }
}
::v-deep .el-dialog__footer {
  .el-button {
    width: 130px;
  }
}
</style>
