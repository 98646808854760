<template>
  <div>
    <el-container>
      <el-aside width="250px">
        <div class="title">咨询系统</div>
        <user></user>
        <Navigation/>
      </el-aside>
      <div class="main-wrap">
        <el-main>
          <transition mode="out-in" name="fade">
            <router-view class="view-box"></router-view>
          </transition>
        </el-main>
        <el-footer>
          <el-link class='company_link'
                   href='http://xinli121.com/'
                   target='_blank' :underline="false">技术服务支持 ©{{ (new Date()).getFullYear() }} 辰微沐阳
          </el-link>
        </el-footer>
      </div>
    </el-container>
  </div>
</template>

<script>
import Navigation from './components/navigation'
import user from "./components/user";

export default {
  components: {Navigation, user},
  name: "layout",
  created() {
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  background: rgb(246, 246, 246);
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .el-aside {
    background: #081C27;
    padding-top: 28px;
    .title {
      font-size: 26px;
      color: rgba(78, 111, 218, 1);
      display: table;
      margin: 0 auto;
    }
  }

  //.el-header {
  //  display: flex;
  //  justify-content: flex-end;
  //}

  .main-wrap {
    width: calc(100vw - 250px);
    .el-footer {
      height: 0 !important;
    }
  }

  .el-main {
    height: calc(100vh - 30px);
    box-sizing: border-box;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #909399;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #131313;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #434343;
    }
  }
}
.view-box {
  min-height: 100%;
  min-width: 1300px;
  width: 100%;

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
