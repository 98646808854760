<template>
  <div class="user">
    <el-avatar :src="userInfo && userInfo.headerImg" ondragstart="return false"></el-avatar>
    <el-dropdown @command="command">
      <span class="el-dropdown-link">
        {{userInfo && userInfo.loginName}}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { userInfo } from "../../api";

export default {
  name: "user",
  data() {
    return {
      userInfoList: {}
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created() {
    // this.getUserInfo()
  },
  methods: {
    ...mapMutations(['removeToken']),
    command(e) {
      if (e === 'loginout') {
        this.removeToken()
        localStorage.clear()
        this.$router.push({path: '/login'})
      }
    },
    // 获取登录用户信息
    getUserInfo() {
      userInfo().then(res => {
        this.$store.commit('setUserInfo', res.data)
      }).catch(err => {
        this.$message.error('当前用户不存在与该系统')
        console.log(2)
        this.$store.commit('removeToken')
        localStorage.clear()
        this.$router.push({path: '/login'})
      })
      // userInfo().then(data => {
      //   if (data.code === 200) {
      //     this.userInfoList = data.data
      //     console.log(data.data)
      //   } else {
      //     this.$message.error(data.message)
      //   }
      // })
      //     .catch(error => {
      //     })
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-top: 25px;
  margin-bottom: 40px;

  .el-avatar {
    margin-bottom: 8px;
    height: 80px;
    width: 80px;
  }

  .el-dropdown {
    margin-left: 20px;
    cursor: pointer;
    .el-dropdown-link {
      font-size: 18px;
      color: rgba(226, 226, 226, 1);
    }
  }
}
</style>
