<template>
  <el-tabs v-model="activeName"  @tab-click="handleClick">
      <el-tab-pane label="三方应用管理" name="first"><app v-if="isFirst"></app></el-tab-pane>
      <el-tab-pane label="客户单位管理" name="second"><org v-if="isSecond"></org></el-tab-pane>
      <el-tab-pane label="客户单位服务套餐管理" name="third"><meal v-if="isThird"></meal></el-tab-pane>
      <el-tab-pane label="客户单位咨询记录" name="fourth"><record v-if="isFourth"></record></el-tab-pane>
    </el-tabs>
</template>

<script>
import app from './application/Application'
import org from './org/Org'
import meal from './meal/Meal'
import record from './record/Record'

export default {
  name: "Information",
  components: { app, org, meal, record },
  data() {
    return {
      activeName: 'first',
      isFirst: true,
      isSecond: false,
      isThird: false,
      isFourth: false
    }
  },
  mounted() {
    // this.tableLocal()
  },
  methods: {
    // 刷新页面 页面保持在当前列表
    tableLocal() {
      if (localStorage.getItem('task') != null) {
        this.activeName = localStorage.getItem('task')
      }
    },
    handleClick(tab, event) {
      // localStorage.setItem('task', tab.name)
      // 根据切换的tab栏来请求后端数据
      if (tab.name === 'first') {
        this.isFirst= true
        this.isSecond= false
        this.isThird= false
        this.isFourth= false
      } else  if (tab.name === 'second') {
        this.isFirst= false
        this.isSecond= true
        this.isThird= false
        this.isFourth= false
      } else  if (tab.name === 'third') {
        this.isFirst= false
        this.isSecond= false
        this.isThird= true
        this.isFourth= false
      } else if (tab.name === 'fourth'){
        this.isFirst= false
        this.isSecond= false
        this.isThird= false
        this.isFourth= true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
