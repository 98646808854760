<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      @closed="resetFields"
      :close-on-click-modal="false"
      center>
    <el-form label-width="120px" :model="form" :rules="addRule" ref="addForm" :disabled="disable">
      <el-row :gutter="0">
        <el-col :span="10">
          <el-form-item label="选择所在应用" size="mini" prop="applyId">
            <el-select placeholder="选择所在应用" v-model="form.applyId">
              <el-option v-for="(item,index) in appList"
                         :key="index"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :offset="5" :span="9">
          <el-form-item label="客户单位名称" size="mini" prop="name">
            <el-input v-model="form.name" placeholder="输入客户单位名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="10">
          <el-form-item label="机构ID" size="mini" prop="deptId">
            <el-input v-model="form.deptId" placeholder="输入机构ID"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowSave" v-show="!disable">确 定</el-button>
      <el-button @click="dialogVisible = false" v-show="!disable">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addOrg, updateOrg, getAppIdList } from '@/api/three'
// import {getCounselorList} from "../../../api";

export default {
  name: "ApplicationDialog",
  data() {
    return {
      title: '',
      form: {},
      // 获取下拉列表
      appList: [],
      dialogVisible: false,
      // 是否禁用所有项
      disable: false,
      // 新增的校验规则
      addRule: {
        name: [{required: true, message: "客户单位名称不能为空", trigger: "blur"}],
        applyId: [{required: true, message: "三方应用ID不能为空", trigger: "blur"},
          {validator: this.validateNum, trigger: "blur"}],
        deptId: [{required: true, message: "机构ID不能为空", trigger: "blur"},
          {validator: this.validateNum, trigger: "blur"}],
      }
    }
  },
  methods: {
    handleShow() {
      this.dialogVisible = true
    },
    // 验证表单-服务时长必须为整数数字
    validateNum(rule, value, callback) {
      if(!Number(value)) {
        return callback(new Error("请输入数字值"));
      } else {
        return callback()
      }
    },
    // 重置表单信息
    resetFields() {
      this.disable = false
      this.form ={}
      this.$refs.addForm.resetFields()
    },
    // 保存
    rowSave() {
      this.$refs.addForm.validate(valid => {
        if (!valid) {
          return this.$message.error('请填写必要的表单项！')
        }
        const row = this.form
        row.applyId = this.appList.filter(v=>{
          return v.id == row.applyId
        })[0].id
        row.appId = this.appList.filter(v=>{
          return v.id == row.applyId
        })[0].appId
        this.loading = true
        if(row.id) {
          updateOrg(row).then(data => {
            if (data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
              .catch(err => {
                this.loading = false
              })
        } else {
          addOrg(row).then( data => {
            if (data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
    // 获取应用下拉列表
    getAppIdList() {
      getAppIdList().then(data => {
        if (data.code === 200) {
          this.appList = data.data
          console.log(this.appList)
        } else {
          this.$message.error(data.message)
        }
      })
          .catch(error => {
          })
    }
  }
}
</script>

<style lang="scss" scoped>
img{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
</style>
