import { download, getConsultVideo } from '@/api'
import { openDownloadDialog } from '@/utils'

export default {
    data() {
        return {
            title: '',
            dialogVisible: false,
            form: {},
            //初始值为false
            btnDisabled: false,
        }
    },
    methods: {
        // 重置表单信息
        resetFields() {
            this.btnDisabled = false
        },
        async download() {
            this.btnDisabled = true;
            const { msg } = await getConsultVideo(this.form.videoUrl)
            window.open(msg)
            // let params = {
            //     videoUrl: this.form.videoUrl
            // }
            // let res = await download(params)
            // openDownloadDialog(res, this.form.serviceName || this.form.customerName)
        }
    }
}