<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      @closed="resetFields"
      :close-on-click-modal="false"
      center>
    <el-form label-width="120px" :model="form" :rules="addRule" ref="addForm" :disabled="disable">
      <el-row :gutter="0">
        <el-col :span="10">
          <el-form-item label="服务名称" size="mini" prop="contentId">
            <el-select placeholder="选择对应服务" v-model="form.contentId">
              <el-option v-for="(item,index) in contentList"
                         :key="index"
                         :label="item.serviceName"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :offset="5" :span="9">
          <el-form-item label="客户单位名称" size="mini" prop="orgId">
            <el-select placeholder="选择对应客户单位" v-model="form.orgId">
              <el-option v-for="(item,index) in insList"
                         :key="index"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="10">
          <el-form-item label="可使用次数" size="mini" prop="useNum">
            <el-input v-model="form.useNum" placeholder="输入可使用次数"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowSave" v-show="!disable">确 定</el-button>
      <el-button @click="dialogVisible = false" v-show="!disable">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addMeal, updateMeal, getContentList, getInsList } from '../../../api/three.js'


export default {
  name: "MealDialog",
  data() {
    return {
      title: '',
      form: {},
      // 服务下拉列表
      contentList: [],
      // 机构下拉列表
      insList: [],
      dialogVisible: false,
      // 是否禁用所有项
      disable: false,
      // 新增的校验规则
      addRule: {
        orgId: [{required: true, message: "客户单位名称不能为空", trigger: "blur"}],
        contentId: [{required: true, message: "服务名称不能为空", trigger: "blur"}],
        useNum: [{required: true, message: "使用次数不能为空", trigger: "blur"},
          {validator: this.validateNum, trigger: "blur"}],
      }
    }
  },
  created() {
    // 获取服务下拉列表
    this.getContentList()
    // 获取机构下拉列表
    this.getInsList()
  },
  methods: {
    handleShow() {
      this.dialogVisible = true
    },
    // 验证表单-服务时长必须为整数数字
    validateNum(rule, value, callback) {
      if(!Number(value)) {
        return callback(new Error("请输入数字值"));
      } else {
        return callback()
      }
    },
    // 重置表单信息
    resetFields() {
      this.disable = false
      this.form ={}
      this.$refs.addForm.resetFields()
    },
    // 保存
    rowSave() {
      this.$refs.addForm.validate(valid => {
        if (!valid) {
          return this.$message.error('请填写必要的表单项！')
        }
        const row = this.form
        row.contentId = this.contentList.filter(v=>{
          return v.id == row.contentId
        })[0].id                                                //  服务内容ID
        row.contentName = this.contentList.filter(v=>{
          return v.id == row.contentId
        })[0].serviceName                                      //  服务内容名称
        row.consultantName = this.contentList.filter(v=>{
          return v.id == row.contentId
        })[0].consultantName                                   //  咨询师名字

        row.orgId = this.insList.filter(v=>{
          return v.id == row.orgId
        })[0].id                                                //  机构主键ID
        row.deptName = this.insList.filter(v=>{
          return v.id == row.orgId
        })[0].name                                              //  机构名字
        row.deptId = this.insList.filter(v=>{
          return v.id == row.orgId
        })[0].deptId                                            //  机构ID

        this.loading = true
        if(row.id) {
          updateMeal(row).then(data => {
            if (data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          }).catch(error => {
                this.loading = false
            })
        } else {
          addMeal(row).then( data => {
            if (data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
    // 获取服务下拉列表
    getContentList() {
      getContentList().then(data => {
        if (data.code === 200) {
          this.contentList = data.data
        } else {
          this.$message.error(data.message)
        }
      })
          .catch(error => {
          })
    },
    // 获取机构下拉列表
    getInsList() {
      getInsList().then(data => {
        if (data.code === 200) {
          this.insList = data.data
        } else {
          this.$message.error(data.message)
        }
      })
          .catch(error => {
          })
    }
  }
}
</script>

<style lang="scss" scoped>
img{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
</style>
