<template>
  <el-row>
    <div class="top">
      <el-col :span="24">
        <el-form inline size='small'>
          <el-form-item>
            <el-input v-model="page.name" placeholder="输入顾客名字" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="page.code" placeholder="输入顾客编码" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-search'
                       size="small"
                       @click="page.currentPage=1;getClientList()"
                       type="primary">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-form inline size='small' style="text-align: right" >
        <el-form-item class="top_right">
          <el-button icon='el-icon-circle-plus-outline' size="small" type="primary" @click='addData'>新增</el-button>
          <!--          批量删除-->
          <el-button icon='el-icon-delete' size="small" class="del" @click="delClient()">删除</el-button>
        </el-form-item>
      </el-form>
    </div>
    <RzTableVue v-loading='loading'
                :columns='columns'
                :data='tableData'
                :page='page'
                stripe
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @selection-change='selection'
                :border="true">
      <template #avatar="{row}">
        <el-image :src="row.avatar" style="width: 50px; height: 50px" :preview-src-list="[row.avatar]">
          <div slot="error" class="image-slot">
            <img src="../../assets/img/failtoload50x50.png" />
          </div>
        </el-image>
      </template>
      <template #consultTime="{row}">
        {{formatTime(row.consultTime)}}
      </template>
      <template #numbers="{row}">
        {{row.numbers}}次
      </template>
      <template #countTime="{row}">
        {{row.countTime ? row.countTime + '分钟' : ''}}
      </template>
      <template #sex="{row}">
        {{row.sex == 1 ? "男" : "女"}}
      </template>
      <template #action="{row}" class="action">
        <el-button type='text' @click='updateClient(row)' class="action_update">修改</el-button>
        <el-button type='text' @click='delClient(row.id)' class="action_del">删除</el-button>
        <el-button type='text' @click='detailClient(row)' class="action_detail">详情</el-button>
      </template>
    </RzTableVue>
    <client-dialog ref="client" @success="getClientList()"/>
  </el-row>
</template>

<script>
import RzTableVue from '../../components/table/RzTable.vue'
import clientDialog from './ClientDialog.vue'
import { getClientList, delClient } from '@/api'
import dayjs from 'dayjs'

export default {
  name: "Client",
  components: { RzTableVue, clientDialog },
  data() {
    return {
      tableData: [],
      columns: [
        {
          type: 'selection',
          key: 'index',
          align: 'center'
        },
        {
          label: '顾客编码',
          key: 'code',
          prop: 'code',
          align: 'center',
        },
        {
          label: '顾客姓名',
          key: 'name',
          prop: 'name',
          align: 'center',
          // sortable: true,
        },
        {
          label: '顾客电话',
          key: 'phone',
          prop: 'phone',
          align: 'center',
          // sortable: true,
        },
        {
          label: '头像',
          key: 'avatar',
          prop: 'avatar',
          align: 'center',
          // sortable: true,
          scopedSlots: 'avatar',
        },
        {
          label: '顾客性别',
          key: 'sex',
          prop: 'sex',
          align: 'center',
          // sortable: true,
          scopedSlots: 'sex',
        },
        {
          label: '顾客年龄',
          key: 'age',
          prop: 'age',
          align: 'center',
        },
        {
          label: '咨询次数',
          key: 'numbers',
          prop: 'numbers',
          align: 'center',
          // sortable: true,
          scopedSlots: 'numbers',
        },
        {
          label: '累计时长',
          key: 'countTime',
          prop: 'countTime',
          align: 'center',
          // sortable: true,
          scopedSlots: 'countTime'
        },
        {
          label: '最近咨询时间',
          key: 'consultTime',
          prop: 'consultTime',
          align: 'center',
          width: 200,
          // sortable: true,
          scopedSlots: 'consultTime'
        },
        {
          label: '操作',
          key: 'action',
          prop: 'action',
          align: 'center',
          // sortable: true,
          scopedSlots: 'action',
        }],
      loading: false,
      page: {
        name: '',
        code: '',
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
      },
      // 选择的多个id
      ids: []
    }
  },
  created() {
    this.getClientList()
  },
  methods: {
    // 监听 pageSize 改变的事件
    handleSizeChange(newSize) {
      this.page.pageSize = newSize
      this.getClientList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page.currentPage = newPage
      this.getClientList()
    },
    // 时间格式化
    formatTime(r) {
      if(r) {
        return dayjs(r).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    // 新增
    addData() {
      this.$refs.client.title = '新增'
      this.$refs.client.update = true
      this.$refs.client.dialogVisible = true
    },
    // 修改
    updateClient(row) {
      this.$refs.client.title = '修改'
      this.$refs.client.update = true
      this.$refs.client.form = {...row}
      this.$refs.client.dialogVisible = true
    },
    // 详情
    detailClient(row) {
      this.$refs.client.title = '详情'
      this.$refs.client.disable = true
      this.$refs.client.update = false
      this.$refs.client.form = {...row}
      this.$refs.client.dialogVisible = true
    },
    // 多项选择
    selection(e) {
      this.ids = e
    },
    // 删除顾客信息
    delClient(id) {
      let ids = this.ids.map(val => {
        return val.id
      })
      if (!id && this.ids.length == 0) {
        this.$message.error('请选择数据')
        return false
      }
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let r = await delClient(id ? [id] : ids)
        if (r.code == 200) {
          this.$message.success('删除成功')
          this.getClientList()
        }
      }).catch(err => err)
    },
    // 获取顾客信息列表
    getClientList() {
      this.loading = true
      const query = {
        pageSize: this.page.pageSize,
        currPage: this.page.currentPage,
        name: this.page.name,
        code: this.page.code
      }
      getClientList(query).then(({ code, data }) => {
        this.loading = false
        if (code == 200) {
          this.tableData = data.list
          this.page.totalCount = data.totalCount
        }
      }).catch(err => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  justify-content: space-between;
  form.el-form.el-form--inline {
    width: 600px;
  }
  .el-col {
    padding-left: 0 !important;
  }
  .top_right {
    margin-right: 0 !important;
  }
  .add {
    background: #4E6FDA;
    color: #FFF8F8;
  }
  .del {
    background: #EC2B2B;
    color: #FFF8F8;
  }
}
</style>
