<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="20%"
      @closed="resetFields"
      :close-on-click-modal="false"
      center>
    <el-form label-width="80px"
             label-position="left"
             :model="form"
             :rules="addRule"
             :disabled="disable"
             ref="addForm">
      <el-form-item label="顾客姓名" prop="name">
        <el-input v-model="form.name" placeholder="输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="顾客性别" prop="sex">
        <el-radio-group v-model="form.sex" size="small">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input v-model="form.phone" placeholder="输入电话号码"></el-input>
      </el-form-item>
      <el-form-item label="生日" prop="birthday">
        <el-date-picker
            v-model="form.birthday"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
<!--      <el-form-item label="顾客年龄" prop="age">-->
<!--        <el-input v-model="form.age" placeholder="输入顾客年龄"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="头像">
        <el-upload  :show-file-list="false"
                    action=""
                    :http-request="uploadAvatar"
                    accept=".jpg,.jpeg,.png"
                    :limit="1"
                    ref="uploadAvatar"
                    :on-success="handleUploadAvatarSuccess"
                    list-type="picture-card"
                    v-show="form.avatar || update">
          <i class="el-icon-plus" v-show="!form.avatar"></i>
          <el-image :src="form.avatar" style="width: 148px; height: 148px" v-show="form.avatar">
            <div slot="error" class="image-slot">
              <img src="../../assets/img/failtoload150x150.png" />
            </div>
          </el-image>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowSave" v-show="!disable">确 定</el-button>
      <el-button @click="dialogVisible = false" v-show="!disable">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { upLoad, addClient, updataClient } from '../../api'
import { phoneCheck } from "@/utils/formCheck"

export default {
  name: "ClientDialog",
  data() {
    return {
      title: '',
      // 控制修改弹窗 显示上传按钮的条件
      update: false,
      form: {
        name: '',
        avatar: '',
        sex: '',
        code: '',
        age: '',
        numbers: '',
        countTime: '',
        birthday: '',
        phone: ''
      },
      disable: false,
      dialogVisible: false,
      // 新增的校验规则
      addRule: {
        name: [{required: true, message: "姓名不能为空", trigger: "blur"}],
        birthday: [{required: true, message: "生日不能为空", trigger: "blur"}],
        phone: [{required: true, message: "电话不能为空", trigger: "blur"},
          { validator: phoneCheck, message: "手机号码格式不正确", trigger: "blur" }],
        sex: [{required: true, message: "性别不能为空", trigger: "blur"}]
      }
    }
  },
  beforeUpdate() {
    this.getAge()
  },
  methods: {
    handleShow() {
      this.dialogVisible = true
    },
    // 判断用户的年龄
    getAge() {
      let birthdays = new Date(this.form.birthday)
      let d = new Date()
      let clientAge =
          d.getFullYear() - birthdays.getFullYear()
      this.form.age = clientAge
      return clientAge
    },
    // 图片上传成功时
    handleUploadAvatarSuccess(response, file, fileList) {
      this.$refs.uploadAvatar.clearFiles()
    },
    // 验证表单-服务时长必须为整数数字
    validateNum(rule, value, callback) {
      if(!Number(value)) {
        return callback(new Error("请输入数字值"));
      } else {
        return callback()
      }
    },
    // 验证表单-只能输入1或2
    validateSex(rule, value, callback) {
      if(value !== 1 || 2) {
        return callback(new Error("请输入正确的值"));
      } else {
        return callback()
      }
    },
    // 重置表单信息
    resetFields() {
      this.disable = false
      this.form={}
      this.$refs.addForm.resetFields()
    },
    // 保存
    rowSave() {
      this.$refs.addForm.validate(valid => {
        if(!valid) {
          return this.$message.error('请填写必要的表单项！')
        }
        const row = this.form
        this.loading = true
        // 如果存在该条信息id 则为修改信息
        if(row.id) {
          updataClient(row).then( data => {
            if(data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
              .catch(err => {
                this.loading = false
              })
        } else {
          addClient(row).then( data => {
            if(data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
              .catch(err => {
                this.loading = false
              })
        }
      })
    },
    // 头像 上传
    async uploadAvatar({data,file}) {
      const formData = new FormData
      formData.append('file',file)
      let response = await upLoad(formData)
      if(response.code == 200) {
        this.form.avatar = response.data
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
img{
  width: 148px;
  height: 148px;
  object-fit: cover;
}
::v-deep .el-input__inner::-webkit-input-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-moz-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-ms-input-placeholder{
  color: #d9d9d9 !important;
}

</style>
