<template>
  <el-dialog
      title="字典数据列表"
      :visible.sync="dialogVisible"
      width="80%"
      top="5vh"
      @closed="reset"
      :close-on-click-modal="false"
      center>
    <el-row>
      <div class="top">
        <el-col :span="24">
          <el-form inline size='small'>
            <el-form-item>
              <el-input placeholder="输入字典编码" v-model="page.code" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button icon='el-icon-search'
                         @click="getDataList()"
                         size="small" type="primary">查询</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-form inline size='small' style="text-align: right" >
          <el-form-item class="top_right">
            <el-button icon='el-icon-circle-plus-outline' size="small" type="primary" @click='addData'>新增</el-button>
            <!--          批量删除-->
            <el-button icon='el-icon-delete' size="small" class="del" @click="delData()">删除</el-button>
          </el-form-item>
        </el-form>
      </div>
      <RzTableVue v-loading='loading'
                  :columns='columns'
                  :data='tableData'
                  stripe
                  show-overflow-tooltip
                  @selection-change='selection'
                  :border="true">
        <template #createdTime="{row}">
          {{formatTime(row.createdTime)}}
        </template>
        <template #state="{row}">
          {{getStatus(row.state)}}
        </template>
        <template #action="{row}">
          <el-button type='text' @click='update(row)' class="action_update">修改</el-button>
          <el-button type='text' @click='delData(row.id)' class="action_del">删除</el-button>
        </template>
      </RzTableVue>
    </el-row>
    <list-dialog ref="listDialog" @success="getDataList()"></list-dialog>
  </el-dialog>
</template>

<script>
import RzTableVue from '../../../components/table/RzTable.vue'
import dayjs from 'dayjs'
import listDialog from './ListDialog'
import { delData, getDataList } from "@/api/sys";

export default {
  name: "List",
  components: { RzTableVue, listDialog },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      columns: [
        {
          type: 'selection',
          key: 'index',
          align: 'center'
        },
        {
          label: '字典类型',
          key: 'type',
          prop: 'type',
          align: 'center',
        },
        {
          label: '字典编码',
          key: 'code',
          prop: 'code',
          align: 'center',
        },
        {
          label: '字典标签',
          key: 'value',
          prop: 'value',
          align: 'center',
        },
        {
          label: '字典排序',
          key: 'orderNum',
          prop: 'orderNum',
          align: 'center',
        },
        {
          label: '状态',
          key: 'state',
          prop: 'state',
          align: 'center',
          scopedSlots: 'state'
        },
        {
          label: '备注',
          key: 'remarks',
          prop: 'remarks',
          align: 'center',
        },
        {
          label: '创建时间',
          key: 'createdTime',
          prop: 'createdTime',
          align: 'center',
          width: 200,
          scopedSlots: 'createdTime'
        },
        {
          label: '操作',
          key: 'action',
          prop: 'action',
          align: 'center',
          // sortable: true,
          scopedSlots: 'action',
        }
      ],
      loading: false,
      page: {
        code: '',
        type: ''
      },
      // 选择的多个id
      ids: []
    }
  },
  methods: {
    reset() {
      this.page.code = ''
    },
    // 咨询状态
    getStatus(e) {
      let status = ''
      switch (e) {
        case 1 :
          status = '启用'
          break
        case 2 :
          status = '未启用'
          break
      }
      return status
    },
    // 修改
    update(row) {
      this.$refs.listDialog.title = '修改'
      this.$refs.listDialog.form = {...row}
      this.$refs.listDialog.dialogVisible = true
    },
    list(row) {
      this.$refs.list.dialogVisible = true
    },
    // 时间格式化
    formatTime(r) {
      if(r) {
        return dayjs(r).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    // 新增
    addData() {
      this.$refs.listDialog.title = '新增'
      this.$refs.listDialog.dialogVisible = true
    },
    // 多项选择
    selection(e) {
      this.ids = e
    },
    // 删除字典信息
    delData(id) {
      let ids = this.ids.map(val => {
        return val.id
      })
      if (!id && this.ids.length == 0) {
        this.$message.error('请选择数据')
        return false
      }
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let r = await delData(id ? id : ids.join(','))
        if (r.code == 200) {
          this.$message.success('删除成功')
          this.getDataList()
        }
      }).catch(err => err)
    },
    // 获取字典数据列表
    getDataList() {
      this.loading = true
      const query = {
        code: this.page.code,
        type: this.page.type
      }
      getDataList(query).then(({ code, data }) => {
        if (code == 200) {
          this.loading = false
          this.tableData = data
        }
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .top {
  display: flex;
  justify-content: space-between;
  form.el-form.el-form--inline {
    width: 600px;
  }

  .el-col {
    padding-left: 0 !important;
  }
  .top_right {
    margin-right: 0 !important;
  }
  .add .search {
    background: #4E6FDA;
    color: #FFF8F8;
  }
  .del {
    background: #EC2B2B;
    color: #FFF8F8;
  }
}
::v-deep .text {
  display: inline-block;
  width: 10em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::v-deep .el-input__inner::-webkit-input-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-moz-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-ms-input-placeholder{
  color: #d9d9d9 !important;
}

</style>
