<template>
  <div id="app">
    <router-view></router-view>
<!--    <Layout></Layout>-->
  </div>
</template>
<script>
import Layout from "@/layout";
import { mapActions } from 'vuex'

export default {
  components: {Layout},
  created() {
    this.getUserInfo()
  },
  methods: {
    ...mapActions(['handleUserInfo']),
    getUserInfo() {
      if (this.$store.state.token) {
        this.handleUserInfo()
        this.$forceUpdate()
      }
    }
  }
}
</script>
<style lang="scss">

</style>
