<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      @closed="resetFields"
      :close-on-click-modal="false"
      center>
    <el-form label-width="120px" :model="form" :rules="addRule" ref="addForm" :disabled="disable">
      <el-row :gutter="0">
        <el-col :span="9">
          <el-form-item label="三方应用ID" size="mini" prop="appId">
            <el-input v-model="form.appId" placeholder="输入三方系统应用ID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="5" :span="9">
          <el-form-item label="应用名称" size="mini" prop="name">
            <el-input v-model="form.name" placeholder="输入应用名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowSave" v-show="!disable">确 定</el-button>
      <el-button @click="dialogVisible = false" v-show="!disable">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addApp, updateApp } from '@/api/three'

export default {
  name: "ApplicationDialog",
  data() {
    return {
      title: '',
      form: {},
      dialogVisible: false,
      // 是否禁用所有项
      disable: false,
      // 新增的校验规则
      addRule: {
        name: [{required: true, message: "应用名称不能为空", trigger: "blur"}],
        appId: [{required: true, message: "三方应用ID不能为空", trigger: "blur"},
          {validator: this.validateNum, trigger: "blur"}],
      }
    }
  },
  methods: {
    handleShow() {
      this.dialogVisible = true
    },
    // 验证表单-服务时长必须为整数数字
    validateNum(rule, value, callback) {
      if(!Number(value)) {
        return callback(new Error("请输入数字值"));
      } else {
        return callback()
      }
    },
   // 重置表单信息
    resetFields() {
      this.disable = false
      this.form ={}
      this.$refs.addForm.resetFields()
    },
    // 保存
    rowSave() {
      this.$refs.addForm.validate(valid => {
        if (!valid) {
          return this.$message.error('请填写必要的表单项！')
        }
        const row = this.form
        this.loading = true
        if(row.id) {
          updateApp(row).then(data => {
            if (data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
              .catch(err => {
                this.loading = false
              })
        } else {
          addApp(row).then( data => {
            if (data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
img{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
</style>
