<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      @closed="resetFields"
      top="5vh"
      :close-on-click-modal="false"
      center>
    <el-form label-width="80px"
             label-position="left"
             :model="form"
             :rules="addRule"
             :disabled="disable"
             ref="addForm">
      <el-row :gutter="0">
        <el-col :span="9">
        </el-col>
        <el-col :offset="5" :span="9">
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="9">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="输入姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="5" :span="9">
          <el-form-item label="身份证" prop="idcard">
            <el-input v-model="form.idcard" placeholder="输入身份证号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="9">
          <el-form-item label="电话" prop="phone">
            <el-input v-model="form.phone" placeholder="输入电话号码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="5" :span="9">
          <el-form-item label="等级" prop="level">
            <el-select placeholder="选择对应等级" v-model="form.level">
              <el-option v-for="(item,index) in levelList"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="9">
          <el-form-item label="头像">
            <el-upload  :show-file-list="false"
                        action=""
                        :http-request="uploadAvatar"
                        accept=".jpg,.jpeg,.png"
                        :limit="1"
                        ref="uploadAvatar"
                        :on-error="imgUploadError"
                        :on-success="handleUploadAvatarSuccess"
                        list-type="picture-card"
                        v-show="form.avatar || update">
              <i class="el-icon-plus" v-show="!form.avatar"></i>
              <el-image :src="form.avatar" style="width: 150px; height: 150px" v-show="form.avatar">
                <div slot="error" class="image-slot">
                  <img src="../../assets/img/failtoload150x150.png" />
                </div>
              </el-image>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :offset="5" :span="9">
<!--          <el-form-item label="简介" prop="profile">-->
<!--            <editor v-model="form.profile"></editor>-->
<!--&lt;!&ndash;            <el-input v-model="form.profile" placeholder="输入个人简介" type="textarea" autosize  :autosize="{ minRows: 4 }"></el-input>&ndash;&gt;-->
<!--          </el-form-item>-->
          <el-form-item label="咨询师性别" prop="sex" label-width="130px">
            <el-radio-group v-model="form.sex" size="small">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
        <el-form-item label="资格证">
          <el-row>
            <el-col :span="6" v-for="(item,index) in form.certificate" style="padding: 0">
              <div class="showPic">
                <el-image :src="item" style="width: 148px; height: 148px" v-show="item">
                  <div slot="error" class="image-slot">
                    <img src="../../assets/img/failtoload150x150.png" />
                  </div>
                </el-image>
                <i class="el-icon-close" @click="form.certificate.splice(index, 1)" v-show="!disable && item"></i>
              </div>
            </el-col>
            <el-col :span="6">
              <el-upload  action=""
                          :http-request="upload"
                          accept=".jpg,.jpeg,.png"
                          ref="upload"
                          :show-file-list="false"
                          list-type="picture-card"
                          v-show="!disable">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
      <el-row :gutter="0">
        <el-col :span="24">
          <el-form-item label="简介" prop="profile">
            <editor v-model="form.profile"></editor>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowSave" v-show="!disable">确 定</el-button>
      <el-button @click="dialogVisible = false" v-show="!disable">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { phoneCheck,idCard } from "@/utils/formCheck"
import { upLoad, addConsultant, updataConsultant } from '../../api'
import {  getDataList } from "@/api/sys";
import editor from '@/components/RzTextEditor'

export default {
  name: "ServeAddDialog",
  components: {
    editor
  },
  data() {
    return {
      title: '',
      // 控制修改弹窗 显示上传按钮的条件
      update: false,
      form: {
        name: '',
        avatar: '',
        idcard: '',
        phone: '',
        profile: '',
        certificate: [],
        level: ''
      },
      imgList: [],
      levelList: [],
      dialogVisible: false,
      // 是否禁用所有项
      disable: false,
      // 新增的校验规则
      addRule: {
        name: [{required: true, message: "姓名不能为空", trigger: "blur"}],
        level: [{required: true, message: "该项不能为空", trigger: "blur"}],
        idcard: [{required: true, message: "身份证不能为空", trigger: "blur"},
          { validator: idCard, message: "身份证格式不正确", trigger: "blur" }],
        phone: [{required: true, message: "电话不能为空", trigger: "blur"},
          { validator: phoneCheck, message: "手机号码格式不正确", trigger: "blur" }],
        profile: [{required: true, message: "简介不能为空", trigger: "blur"}],
        sex: [{required: true, message: "性别不能为空", trigger: "blur"}]
      },
      // 获取字典数据level字段 传参
      type: 'level'
    }
  },
  methods: {
    handleShow() {
      this.dialogVisible = true
    },
    handleUploadAvatarSuccess(response, file, fileList) {
      this.$refs.uploadAvatar.clearFiles()
    },
    // 图片上传失败时
    imgUploadError(err, file, fileList) {
      this.$message.error("上传图片失败!")
    },
    // 重置表单信息
    resetFields() {
      this.disable = false
      this.update = false
      this.form={}
      this.imgList = []
      // 咨询师等级列表
      this.levelList = []
      this.$refs.addForm.resetFields()
    },
    // 保存
    rowSave() {
      this.$refs.addForm.validate(valid => {
        if(!valid) {
          return this.$message.error('请填写必要的表单项！')
        }
        const row = {...this.form}
        row.certificate = row.certificate.join(',')
        this.loading = true
        // 如果存在该条信息id 则为修改信息
        if(row.id) {
          updataConsultant(row).then( data => {
            if(data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
          .catch(err => {
            this.loading = false
          })
        } else {
          addConsultant(row).then( data => {
            if(data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
          .catch(err => {
            this.loading = false
          })
        }
      })
    },
    // 获取字典数据（level字段）列表
    getDataList() {
      this.loading = true
      const query = {
        type: this.type
      }
      getDataList(query).then(({ code, data }) => {
        if (code == 200) {
          this.loading = false
          console.log(data)
          data.forEach(res => {
            this.levelList.push({
              value: res.value,
              label: res.value
            })
          })
        }
      }).catch(err => {
        this.loading = false
      })
    },
    // 资格证 上传
    async upload({data,file}) {
      const formData = new FormData
      formData.append('file',file)
      let response = await upLoad(formData)
      if(response.code == 200) {
        let res = response.data
        console.log(res)
        this.form.certificate.push(res)
        this.$forceUpdate()
      }
    },
    // 头像 上传
    async uploadAvatar({data,file}) {
      const formData = new FormData
      formData.append('file',file)
      let response = await upLoad(formData)
      if(response.code == 200) {
        this.form.avatar = response.data
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
img{
  width: 148px;
  height: 148px;
  object-fit: cover;
}
.showPic {
  position: relative;
  i {
    position: absolute;
    cursor: pointer;
  }
}
::v-deep .el-input__inner::-webkit-input-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-moz-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-ms-input-placeholder{
  color: #d9d9d9 !important;
}

</style>
