<template>
  <ul>
    <template v-for="item in $router.options.routes[1].children">
      <router-link v-if="!item.props.hide" tag="li" :key="item.id" :to="{path:item.path}"
                   :class="{ nav_li : $route.path === item.path }" class="item" @click="console.log(item.id)">
        <!--      <img :src="item.id === Indexy ? item.activeImg: item.img"/>-->
        <!--      <img v-if=" $route.path !== item.path" :src="item.props.img"/>-->
        <!--      <img v-else :src="item.props.activeImg"/>-->
        <i :class="icons[item.props.index]"></i>

          <span>{{ item.name }}</span>
<!--        <el-menu v-else @select="handleSelect">-->
<!--          <el-submenu :index="item.path">-->
<!--            <template slot="title">-->
<!--              <i class="el-icon-location"></i>-->
<!--              <span>{{ item.name }}</span>-->
<!--            </template>-->
<!--            <el-menu-item :index="k.path" v-for="k in item.children">{{ k.name }}</el-menu-item>-->
<!--          </el-submenu>-->

<!--&lt;!&ndash;          &ndash;&gt;-->
<!--        </el-menu>-->
      </router-link>
    </template>
  </ul>
</template>

<script>
export default {
  name: "navigation",
  data() {
    return {
      active: 0,
      icons: [
        'iconfont icon-a-fuwuguanli1',
        'iconfont icon-zixunrenyuan',
        'iconfont icon-kehu',
        'iconfont icon-c',
        'iconfont icon-xitongguanli',
        'iconfont icon-jichuxinxiguanli'
      ]
    }
  },
  // mounted() {
  //   console.log('组件信息：', this. $router.options.routes[1].children)
  // },
  methods: {
    handleSelect(e){
      console.log(e)
      this.$router.push({path:e})
    },
    isActive(path) {
      console.log(path)
      return this.$route.path.includes(path)
    }
  },
}
</script>

<style lang="scss" scoped>
ul {
  width: 100%;
  user-select: none;

  .item {
    width: 100%;
    height: 84px;
    background: #081C27;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    color: rgba(131, 132, 132, 1);
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;

    img {
      margin-bottom: 10px;
      height: 24px;
    }

    i {
      font-size: 26px;
      margin-bottom: 5px;
    }

    .sys {
      padding-left: 31px;
    }
  }

  .nav_li {
    color: #4E6FDA;
    background: rgba(255, 255, 255, .1);
  }

  //li {
  //  width: 100%;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  flex-direction: column;
  //
  //  i {
  //    font-size: 26px;
  //    margin-bottom: 5px;
  //  }

  //&:not(.logo) {
  //  cursor: pointer;
  //  color: #434444;
  //  font-size: 16px;
  //  height: 100px;
  //  transition: all .3s linear;
  //  &.active{
  //    color: $theme-color;
  //  }
  //}
  //
  //&.logo {
  //  height: 85px;
  //  background: $theme-color;
  //  font-size: 23px;
  //  font-family: PingFang SC;
  //  font-weight: 400;
  //  color: #FFFFFF;
  //}
}

</style>
