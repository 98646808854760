<template>
  <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      center
      width="500px"
      title="修改账户信息"
      @closed="resetFields"
      v-loading="loading">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="帐号" prop="loginName">
        <el-input v-model="form.loginName"></el-input>
      </el-form-item>
      <el-form-item label="旧密码" prop="oldPassWord">
        <el-input v-model="form.oldPassWord" type="password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="loginPwd">
        <el-input v-model="form.loginPwd" type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="loginPwd1">
        <el-input v-model="form.loginPwd1" type="password"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { formatPwd } from '@/utils'
import { changePwd } from '@/api'

export default {
  name: "ChangePwd",
  data() {
    const newPsw = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.form.loginPwd) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    }
    return {
      rules: {
        loginName: [{required: true, message: "请输入帐号", trigger: "blur"}],
        loginPwd: [{required: true, message: "请输入新密码", trigger: "blur"}],
        oldPassWord: [{required: true, message: "请输入旧密码", trigger: "blur"}],
        loginPwd1: [{required: true, message: "请再次输入新密码", trigger: "blur"}, {validator: newPsw, trigger: "blur"}]
      },
      loading: false,
      form: {},
      id: "",
      dialogVisible: false
    }
  },
  methods: {
    // 重置表单信息
    resetFields() {
      this.form = {}
      this.$refs.form.resetFields()
    },
    // 提交更改账户信息
    confirm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          const obj = {
            id: this.id,
            loginName: this.form.loginName,
            loginPwd: await formatPwd(this.form.loginPwd),
            oldPassWord: await formatPwd(this.form.oldPassWord)
          }
          changePwd(obj).then(({code}) => {
            this.loading = false
            if (code == 200) {
              this.dialogVisible = false
              this.$message.success("修改成功")
            }
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
    open(id, loginName) {
      this.dialogVisible = true
      this.id = id
      this.$set(this.form, 'loginName', loginName)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>