var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',[_c('div',{staticClass:"top"},[_c('el-col',{attrs:{"span":24}},[_c('el-form',{attrs:{"inline":"","size":"small"}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"输入顾客名字","clearable":true},model:{value:(_vm.page.name),callback:function ($$v) {_vm.$set(_vm.page, "name", $$v)},expression:"page.name"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"输入顾客编码","clearable":true},model:{value:(_vm.page.code),callback:function ($$v) {_vm.$set(_vm.page, "code", $$v)},expression:"page.code"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"icon":"el-icon-search","size":"small","type":"primary"},on:{"click":function($event){_vm.page.currentPage=1;_vm.getClientList()}}},[_vm._v("查询")])],1)],1)],1),_c('el-form',{staticStyle:{"text-align":"right"},attrs:{"inline":"","size":"small"}},[_c('el-form-item',{staticClass:"top_right"},[_c('el-button',{attrs:{"icon":"el-icon-circle-plus-outline","size":"small","type":"primary"},on:{"click":_vm.addData}},[_vm._v("新增")]),_c('el-button',{staticClass:"del",attrs:{"icon":"el-icon-delete","size":"small"},on:{"click":function($event){return _vm.delClient()}}},[_vm._v("删除")])],1)],1)],1),_c('RzTableVue',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"columns":_vm.columns,"data":_vm.tableData,"page":_vm.page,"stripe":"","border":true},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"selection-change":_vm.selection},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":row.avatar,"preview-src-list":[row.avatar]}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('img',{attrs:{"src":require("../../assets/img/failtoload50x50.png")}})])])]}},{key:"consultTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatTime(row.consultTime))+" ")]}},{key:"numbers",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.numbers)+"次 ")]}},{key:"countTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.countTime ? row.countTime + '分钟' : '')+" ")]}},{key:"sex",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.sex == 1 ? "男" : "女")+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"action_update",attrs:{"type":"text"},on:{"click":function($event){return _vm.updateClient(row)}}},[_vm._v("修改")]),_c('el-button',{staticClass:"action_del",attrs:{"type":"text"},on:{"click":function($event){return _vm.delClient(row.id)}}},[_vm._v("删除")]),_c('el-button',{staticClass:"action_detail",attrs:{"type":"text"},on:{"click":function($event){return _vm.detailClient(row)}}},[_vm._v("详情")])]}}])}),_c('client-dialog',{ref:"client",on:{"success":function($event){return _vm.getClientList()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }