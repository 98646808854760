<template>
  <el-row :gutter='0'>
    <div class="top">
      <el-col :span="24">
        <el-form inline size='small'>
          <el-form-item>
            <el-input placeholder="输入顾客姓名" v-model="page.customerName" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-search'
                       @click="page.currentPage=1;getRecordsList()"
                       size="small" type="primary">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </div>
    <RzTableVue v-loading='loading'
                :columns='columns'
                :data='tableData'
                :page='page'
                stripe
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :border="true">
      <template #status="{row}">
        {{getStatus(row.status)}}
      </template>
      <template #serviceDuration="{row}">
        {{row.serviceDuration}}分钟
      </template>
      <template #consultTime="{row}">
        {{formatTime(row.consultTime)}}
      </template>
      <template #consultEndTime="{row}">
        {{formatTime(row.consultEndTime)}}
      </template>
      <template #action="{row}">
        <el-button type='text' @click='detail(row)' class="action_detail">详情</el-button>
      </template>
    </RzTableVue>
    <record ref="detail"></record>
  </el-row>
</template>

<script>
import RzTableVue from '../../../components/table/RzTable.vue'
import { getRecordsList } from '../../../api/three.js'
import record from './RecordDialog'
import dayjs from 'dayjs'

export default {
  name: "Record",
  components: { RzTableVue, record },
  data() {
    return {
      score: null,
      tableData: [],
      columns: [
        {
          label: '顾客ID',
          key: 'customerId',
          prop: 'customerId',
          align: 'center',
        },
        {
          label: '顾客姓名',
          key: 'customerName',
          prop: 'customerName',
          align: 'center',
        },
        {
          label: '客户单位名称',
          key: 'deptName',
          prop: 'deptName',
          align: 'center',
        },
        {
          label: '咨询状态',
          key: 'status',
          prop: 'status',
          align: 'center',
          scopedSlots: 'status'
        },
        {
          label: '咨询师名称',
          key: 'teacherName',
          prop: 'teacherName',
          align: 'center',
        },
        {
          label: '服务时长',
          key: 'serviceDuration',
          prop: 'serviceDuration',
          align: 'center',
          scopedSlots: 'serviceDuration'
        },
        {
          label: '咨询开始时间',
          key: 'consultTime',
          prop: 'consultTime',
          align: 'center',
          width: 200,
          scopedSlots: 'consultTime',
        },
        {
          label: '咨询结束时间',
          key: 'consultEndTime',
          prop: 'consultEndTime',
          align: 'center',
          width: 200,
          scopedSlots: 'consultEndTime',
        },
        {
          label: '操作',
          key: 'action',
          prop: 'action',
          align: 'center',
          scopedSlots: 'action',
        }
      ],
      loading: false,
      page: {
        customerName: '',
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.getRecordsList()
  },
  methods: {
    // 咨询状态
    getStatus(e) {
      let status = ''
      switch (e) {
        case 1 :
          status = '咨询异常'
          break
        case 2 :
          status = '咨询中'
          break
        case 3 :
          status = '咨询完成'
          break
      }
      return status
    },
    // 监听 pageSize 改变的事件
    handleSizeChange(newSize) {
      this.page.pageSize = newSize
      this.getRecordsList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page.currentPage = newPage
      this.getRecordsList()
    },
    // 时间格式化
    formatTime(r) {
      if(r) {
        return dayjs(r).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    // 详情
    detail(row) {
      this.$refs.detail.title = '详情'
      this.$refs.detail.form = {...row}
      this.$refs.detail.dialogVisible = true
    },
    // 获取客户单位咨询记录列表
    getRecordsList() {
      this.loading = true
      const query = {
        pageSize: this.page.pageSize,
        currPage: this.page.currentPage,
        customerName: this.page.customerName,
      }
      getRecordsList(query).then(({ code, data }) => {
        this.loading = false
        if (code == 200) {
          this.tableData = data.list
          this.page.totalCount = data.totalCount
        }
      }).catch(err => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .top {
  display: flex;
  justify-content: space-between;
  form.el-form.el-form--inline {
    width: 600px;
  }

  .el-col {
    padding-left: 0 !important;
  }
  .top_right {
    margin-right: 0 !important;
  }
  .add .search {
    background: #4E6FDA;
    color: #FFF8F8;
  }
  .del {
    background: #EC2B2B;
    color: #FFF8F8;
  }
}
</style>
