import axios from '../utils/request';
const UAA = process.env.VUE_APP_UAA;
const CONSULT = process.env.VUE_APP_CONSULT;
const DICT = process.env.VUE_APP_DICT;

/**
 *  系统管理
 */

/**
 *  用户管理
 */

// 获取用户列表
export function getUserList(data) {
    return axios({
        url: CONSULT + '/admin/page',
        method: 'post',
        data
    })
}

// 删除用户信息
export function delUsers(data) {
    return axios({
        url: CONSULT + '/admin/delete',
        method: 'post',
        data
    })
}
// 修改用户信息
export function updateUsers(data) {
    return axios({
        url: CONSULT + '/admin/update',
        method: 'post',
        data
    })
}

// 新增用户信息
export function addUsers(data) {
    return axios({
        url: CONSULT + '/admin/add',
        method: 'post',
        data
    })
}

/**
 *  字典管理
 */

// 获取字典列表
export function getDictList(data) {
    return axios({
        url: DICT + '/type/list',
        method: 'get',
        params: data
    })
}

// 删除字典信息
export function delDict(data) {
    return axios({
        url: DICT + '/type/delete/'+data,
        method: 'get',
    })
}
// 修改字典信息
export function updateDict(data) {
    return axios({
        url: DICT + '/type/update',
        method: 'post',
        data
    })
}

// 新增字典信息
export function addDict(data) {
    return axios({
        url: DICT + '/type/add',
        method: 'post',
        data
    })
}

/**
 *  字典数据列表
 */

// 获取字典数据列表
export function getDataList(data) {
    return axios({
        url: DICT + '/data/list',
        method: 'get',
        params: data
    })
}

// 删除字典数据信息
export function delData(data) {
    return axios({
        url: DICT + '/data/delete/'+data,
        method: 'get',
    })
}
// 修改字典数据信息
export function updateData(data) {
    return axios({
        url: DICT + '/data/update',
        method: 'post',
        data
    })
}

// 新增字典信息
export function addData(data) {
    return axios({
        url: DICT + '/data/add',
        method: 'post',
        data
    })
}
