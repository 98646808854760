<template>
  <div class="Rz-status" :class="[status ? 'text-success' : 'text-danger', { readonly: readonly }]" @click="check">
    {{ status ? openLabel || '启用' : closeLabel || '禁用' }}
    <span :class="status ? 'bg-success' : 'bg-danger'"></span>
  </div>
</template>

<script>
export default {
  props: ['status', 'openLabel', 'closeLabel', 'readonly'],
  methods: {
    check(e) {
      this.$emit('change')
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.Rz-status {
  line-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: color 0.15s linear;
  span {
    margin-left: 5px;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
}
.Rz-status:not(.readonly):active {
  opacity: 0.78;
}
</style>
