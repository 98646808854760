<template>
  <el-dialog
      :append-to-body="true"
      :title="title"
      :visible.sync="dialogVisible"
      width="500px"
      @closed="resetFields"
      :close-on-click-modal="false"
      center>
    <el-form label-width="80px"
             label-position="left"
             :model="form"
             :rules="addRule"
             ref="addForm">
      <el-form-item label="字典类型" prop="type">
        <el-input v-model="form.type" placeholder="输入字典类型"></el-input>
      </el-form-item>
      <el-form-item label="字典编码" prop="code">
        <el-input v-model="form.code" placeholder="输入字典编码"></el-input>
      </el-form-item>
      <el-form-item label="字典标签" prop="value">
        <el-input v-model="form.value" placeholder="输入字典标签"></el-input>
      </el-form-item>
      <el-form-item label="字典排序" prop="orderNum">
        <el-input v-model="form.orderNum" placeholder="输入字典排序"></el-input>
      </el-form-item>
      <el-form-item label="字典状态" prop="state">
        <el-radio-group v-model="form.state" size="small">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="2">未启用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks" placeholder="输入备注" type="textarea" autosize  :autosize="{ minRows: 4 }"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rowSave">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateData, addData } from '@/api/sys'

export default {
  name: "ListDialog",
  data() {
    return {
      title: '',
      form: {},
      dialogVisible: false,
      // 表单校验规则
      addRule: {
        code: [{required: true, message: "字典编码不能为空", trigger: "blur"}],
        orderNum: [{required: true, message: "字典排序不能为空", trigger: "blur"}],
        value: [{required: true, message: "字典标签不能为空", trigger: "blur"}],
        type: [{required: true, message: "字典类型不能为空", trigger: "blur"}],
        state: [{required: true, message: "请选择字典状态", trigger: "blur"}]
      }
    }
  },
  methods: {
    // 重置表单信息
    resetFields() {
      this.form={}
      this.$refs.addForm.resetFields()
    },
    // 保存
    rowSave() {
      this.$refs.addForm.validate(valid => {
        if(!valid) {
          return this.$message.error('请填写必要的表单项！')
        }
        const row = this.form
        console.log(row)
        this.loading = true
        // 如果存在该条信息id 则为修改信息
        if(row.id) {
          updateData(row).then( data => {
            if(data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
              .catch(err => {
                this.loading = false
              })
        } else {
          addData(row).then( data => {
            if(data.code == 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.loading = false
            }
          })
              .catch(err => {
                this.loading = false
              })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
img{
  width: 148px;
  height: 148px;
  object-fit: cover;
}
::v-deep .el-input__inner::-webkit-input-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-moz-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-ms-input-placeholder{
  color: #d9d9d9 !important;
}

</style>
