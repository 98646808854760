import Vue from 'vue'
import Vuex from 'vuex'
import { getValue, setValue, removeKey } from "../utils";
import { userInfo } from "../api";
import {Message} from "element-ui";
import router from "../router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      token: getValue('ACCESS_TOKEN') ? getValue('ACCESS_TOKEN') : '',
      userInfo: null // 当前用户信息
  },
  mutations: {
      // 存入token
      setToken(state, payLoad) {
          setValue('ACCESS_TOKEN', payLoad)
          state.token = payLoad
      },
      // 清空token
      removeToken(state) {
          state.token = '';
          removeKey('ACCESS_TOKEN');
          location.reload()
      },
      // 存入用户信息
      setUserInfo(state, payLoad) {
        state.userInfo = payLoad
      }
  },
  actions: {
    // 获取用户信息
    handleUserInfo({ commit }) {
      userInfo().then(res => {
        if (res.code == 200) {
            commit('setUserInfo', res.data)
        } else if (res.error.code == 500) {
            Message.error(res.error.message)
            commit('removeToken')
        }
      }).catch(err => {
          console.log(err)
          Message.error('当前用户不存在于该系统或登录已过期')
          commit('removeToken')
      })
    }
  },
  modules: {
  }
})
