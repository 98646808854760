<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      @closed="resetFields"
      top="5vh"
      :close-on-click-modal="false"
      center>
    <el-form label-width="80px"
             label-position="left"
             :model="teacherAccount"
             :rules="addRule"
             :disabled="disable"
             ref="addForm">
      <el-row :gutter="0">
        <el-col :span="9">
        </el-col>
        <el-col :offset="5" :span="9">
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="10">
          <el-form-item label="咨询师帐号" prop="loginName" label-width="100px">
            <el-input v-model="teacherAccount.loginName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="5" :span="9">
          <el-form-item label="咨询师密码" label-width="100px">
            <el-input v-model="teacherAccount.loginPwd" type="password" disabled></el-input>
            <el-button type="text" @click="changPwd">修改帐号密码</el-button>
            <el-button type="text" @click="reset">重置密码</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
<!--    <reset ref="reset"></reset>-->
    <change-pwd ref="change"></change-pwd>
  </el-dialog>
</template>

<script>
import {getConsultantAccount, resetPwd} from '@/api'
import reset from './ResetPwd'
import changePwd from './ChangePwd'

export default {
  name: "ConsultantAccountDialog",
  components: {  changePwd },
  data() {
    return {
      title: '',
      // 咨询师帐号信息
      teacherAccount: {},
      dialogVisible: false,
      // 是否禁用所有项
      disable: false,
      // 新增的校验规则
      addRule: {
        loginName: [{required: true, message: "咨询师账号不能为空", trigger: "blur"}],
      }
    }
  },
  created() {},
  methods: {
    handleShow() {
      this.dialogVisible = true
    },
    // 重置表单信息
    resetFields() {
      this.disable = false
      this.form={}
      this.$refs.addForm.resetFields()
    },
    // 获取咨询师帐号信息
    getConsultantAccount(row) {
      this.loading = true
      const param = {
        teacherId: row.id
      }
      getConsultantAccount(param).then(({code, data}) => {
        this.loading = false
        if (code == 200) {
          this.teacherAccount = data
          console.log(data)
        }
      }).catch(err => {
        this.loading = false
      })
    },
    // 修改密码弹窗
    changPwd() {
      const res = {...this.teacherAccount}
      this.$refs.change.open(res.id, res.loginName)
    },
    // 重置密码弹窗
    reset() {
      // this.$refs.reset.open(this.teacherAccount)
      const account = this.teacherAccount.loginName
      this.$confirm('此操作将重置帐号:'+ account +'的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const id = {
          id: this.teacherAccount.id
        }
        let r = await resetPwd(id)
        if (r.code == 200) {
          this.$message.success('重置密码成功')
          this.show = false
        }
      }).catch(err => err)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner::-webkit-input-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-moz-placeholder{
  color: #d9d9d9 !important;
}
::v-deep .el-input__inner::-ms-input-placeholder{
  color: #d9d9d9 !important;
}

</style>
