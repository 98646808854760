<template>
  <el-row>
    <div class="top">
      <el-col :span="24">
        <el-form inline size='small'>
          <el-form-item>
            <el-input placeholder="输入管理员名字" v-model="page.realName" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="输入手机号码" v-model="page.mobile" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-search'
                       @click="page.currentPage=1;getUserList()"
                       size="small" type="primary">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-form inline size='small' style="text-align: right" >
        <el-form-item class="top_right">
          <el-button icon='el-icon-circle-plus-outline' size="small" type="primary" @click='addData'>新增</el-button>
          <!--          批量删除-->
          <el-button icon='el-icon-delete' size="small" class="del" @click="delUsers()">删除</el-button>
        </el-form-item>
      </el-form>
    </div>
    <RzTableVue v-loading='loading'
                :columns='columns'
                :data='tableData'
                :page='page'
                stripe
                show-overflow-tooltip
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @selection-change='selection'
                :border="true">
      <template #headerImg="{row}">
        <el-image :src="row.headerImg" style="width: 50px; height: 50px" :preview-src-list="[row.headerImg]">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </template>
      <template #action="{row}">
        <el-button type='text' @click='update(row)' class="action_update">修改</el-button>
        <el-button type='text' @click='delUsers(row.id)' class="action_del">删除</el-button>
        <el-button type='text' @click='detail(row)' class="action_detail">详情</el-button>
      </template>
    </RzTableVue>
    <sys ref="sys" @success="getUserList()"/>
  </el-row>
</template>

<script>
import RzTableVue from '../../../components/table/RzTable.vue'
import sys from './UsersDialog'
import { getUserList, delUsers } from '@/api/sys'

export default {
  name: "Users",
  components: { RzTableVue, sys },
  data() {
    return {
      tableData: [],
      columns: [
        {
          type: 'selection',
          key: 'index',
          align: 'center'
        },
        {
          label: '管理员ID',
          key: 'id',
          prop: 'id',
          align: 'center',
          // sortable: true,
        },
        {
          label: '管理员名字',
          key: 'realName',
          prop: 'realName',
          align: 'center',
        },
        {
          label: '登录名称',
          key: 'loginName',
          prop: 'loginName',
          align: 'center',
        },
        {
          label: '头像',
          key: 'headerImg',
          prop: 'headerImg',
          align: 'center',
          scopedSlots: 'headerImg'
        },
        {
          label: '手机号码',
          key: 'mobile',
          prop: 'mobile',
          align: 'center',
        },
        {
          label: '身份证号码',
          key: 'idcard',
          prop: 'idcard',
          align: 'center',
        },
        {
          label: '操作',
          key: 'action',
          prop: 'action',
          align: 'center',
          // sortable: true,
          scopedSlots: 'action',
        }
      ],
      loading: false,
      page: {
        realName: '',
        mobile: '',
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
      },
      // 选择的多个id
      ids: []
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    // 监听 pageSize 改变的事件
    handleSizeChange(newSize) {
      this.page.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page.currentPage = newPage
      this.getUserList()
    },
    // 修改
    update(row) {
      this.$refs.sys.title = '修改'
      this.$refs.sys.form = {...row}
      this.$refs.sys.dialogVisible = true
    },
    // 新增
    addData() {
      this.$refs.sys.title = '新增'
      this.$refs.sys.dialogVisible = true
    },
    // 详情
    detail(row) {
      this.$refs.sys.title = '详情'
      this.$refs.sys.disable = true
      this.$refs.sys.form = {...row}
      this.$refs.sys.dialogVisible = true
    },
    // 多项选择
    selection(e) {
      this.ids = e
    },
    // 删除咨询服务信息
    delUsers(id) {
      let ids = this.ids.map(val => {
        return val.id
      })
      if (!id && this.ids.length == 0) {
        this.$message.error('请选择数据')
        return false
      }
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let r = await delUsers(id ? [id] : ids)
        if (r.code == 200) {
          this.$message.success('删除成功')
          this.getUserList()
        }
      }).catch(err => err)
    },
    // 获取用户信息
    getUserList() {
      this.loading = true
      const query = {
        pageSize: this.page.pageSize,
        currPage: this.page.currentPage,
        realName: this.page.realName,
        mobile: this.page.mobile
      }
      getUserList(query).then(({ code, data }) => {
        this.loading = false
        if (code == 200) {
          this.tableData = data.list
          this.page.totalCount = data.totalCount
          this.$store.dispatch('handleUserInfo')
        }
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .top {
  display: flex;
  justify-content: space-between;
  form.el-form.el-form--inline {
    width: 600px;
  }

  .el-col {
    padding-left: 0 !important;
  }
  .top_right {
    margin-right: 0 !important;
  }
  .add .search {
    background: #4E6FDA;
    color: #FFF8F8;
  }
  .del {
    background: #EC2B2B;
    color: #FFF8F8;
  }
}
::v-deep .text {
  display: inline-block;
  width: 10em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
