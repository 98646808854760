<template>
  <el-dialog :title="title"
             :visible.sync="dialogVisible"
             width="35%"
             :close-on-click-modal="false"
             top="5vh"
             center>
    <el-form :model="form">
      <el-row type="flex" justify="space-between">
        <el-col><el-tag effect="plain">{{form.serviceName}}</el-tag></el-col>
        <el-col style="text-align: right;line-height: 50px" >
          价格：¥&nbsp<span class="price">{{form.price}}</span>
        </el-col>
      </el-row>
      <el-row>
        <span class="title">概要:</span>&nbsp&nbsp
        <span class="content">{{form.serviceOutline}}</span>
      </el-row>
      <el-row v-show="form.serviceImage">
        <div class="img">
          <el-image :src="form.serviceImage" fit="fill">
            <div slot="error" class="image-slot">
              <img src="../../assets/img/failtoload.png" />
            </div>
          </el-image>
        </div>
      </el-row>
      <el-row>
        <span class="title">服务内容:</span>&nbsp&nbsp
        <span class="content">{{form.serviceContent}}</span>
      </el-row>
      <el-row>
        <span class="title">服务时长:</span>&nbsp&nbsp
        <span class="content">{{form.serviceDuration}}分钟。</span>
      </el-row>
    </el-form>
    <el-form :model="form">
      <el-row type="flex" justify="start" align="middle"  >
        <el-col :span="3">
          <el-avatar :src="form.avatar" :size="55"></el-avatar>
        </el-col>
        <span class="name">{{form.consultantName}}</span>
        <el-col :span="3">
          <el-tag type="warning" style="border-radius: 30px; margin-top: 0">
            {{ form.level }}咨询师
          </el-tag>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <span class="content">{{form.consultantProfile}}</span>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
name: "ServeDetailDialog",
  data() {
    return {
      title: '',
      dialogVisible: false,
      form: {}
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 5px 0;
  .el-dialog__title {
    font-weight: 400;
    line-height: 28px;
  }
  .el-dialog__headerbtn {
    top: 8px;
  }
}
::v-deep .el-dialog__body {
  padding: 0;
  .el-form {
    padding: 5px 15px 15px;
    border-bottom: 9px solid rgba(241, 241, 241, 1);
    .el-row {
      margin-bottom: 20px;
      .price {
        font-size: 20px;
        font-weight: bolder;
        color: rgba(78, 111, 218, 1);
      }
      .title {
        font-weight: bold;
        color: rgba(16, 16, 16, 1);
      }
      .content {
        color: rgba(133, 133, 133, 1);
      }
      .img {
        .el-image {
          border-radius: 10px;
          width: 100%;
          height: 100%;
        }
      }
      .name {
        color: rgba(0, 0, 0, 1);
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        padding-right: 10px;
      }
    }
    .el-row:last-child {
      margin-bottom: 0;
    }
  }
}
::v-deep .el-dialog__footer {
  .el-button {
    width: 130px;
  }
}
</style>
